import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FoldersState } from '../../store/reducers/folder.reducer';
import { FoldersActions } from '../../store/actions/folder.actions';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Folder } from '../../store/models/folder';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import { selectError, selectIdCompleted, selectModalType, selectOldFolderName, selectParentId, selectResult } from '../../store/selectors/folder.selectors';

@Component({
  selector: 'app-upsert-folder-modal',
  templateUrl: './upsert-folder-modal.component.html',
  styleUrls: ['./upsert-folder-modal.component.scss']
})
export class UpsertFolderModalComponent implements OnInit {
  @Output() result = new EventEmitter<{ success: boolean, record?: Folder, error?: any }>();
  @Input() companyId:any=null;
  public folderName:string="";
  public folderType:any="";
  modalType$:Observable<any>;
  result$:Observable<any>;
  error$:Observable<any>;
  parentId:any=null;
  isCompleted:boolean=false;
  subscription: Subscription;
  modalType:any=null;


  constructor(
    private store: Store<{ folders: FoldersState }>,
  ) { 
      this.modalType$=store.pipe(
        select(state=>state.folders.upsertFolder.modalType)
      )
      this.subscription = combineLatest([
        this.store.select(selectIdCompleted),
        this.store.select(selectParentId),
        this.store.select(selectModalType),
        this.store.select(selectOldFolderName),
      ]).subscribe(([_isCompeted, _parentId,_modalYype,_foldername]) => {
        this.isCompleted = _isCompeted;
        this.parentId = _parentId;
        this.modalType = _modalYype;
        if(this.modalType=='edit-folder'){
          this.folderName=_foldername;
        }
      });
      this.result$ = this.store.select(selectResult);
      this.error$ = this.store.select(selectError);
  }

  ngOnInit(): void {

  }

  public toggleUpsertFolder(modalType){
    this.store.dispatch(FoldersActions.toggleUpsertFolder(
      {
        upsertFolderProps:{
          modalType:null,
          oldFolderId:null,
          oldFolderName:null,
          targetParentId:null,
          canShowUpsert:false,
          completed:null
        }
      }
    ));
  }

  createFolder(){
    this.store.dispatch(FoldersActions.dismissResult());
    this.store.dispatch(FoldersActions.createFolder({
      folder:{ 
          isCompleted:(this.folderType=='Completed'?true:false),
          companyId:this.companyId,
          folderName:this.folderName,
        }
      }
    ));
    this.checkForResults('create')
  }

  createSubFolder(){
    this.store.dispatch(FoldersActions.dismissResult());
    this.store.dispatch(FoldersActions.createSubFolder({
      subfolder:{ 
          isCompleted:this.isCompleted,
          companyId:this.companyId,
          folderName:this.folderName,
          subFolderId:this.parentId
        }
      }
    ));
    this.checkForResults('create')
  }

  renameFolder(){
    this.store.dispatch(FoldersActions.dismissResult());
    this.store.dispatch(FoldersActions.editFolder({
      folder:{ 
          id:this.parentId,
          folderName:this.folderName,
        }
      }
    ));
    this.checkForResults('edit')
  }
  

  checkForResults(operation){
    this.result$.pipe(
      filter(result => !!result), 
      take(1)
    ).subscribe(result => {
      console.log('Result received:', result);
      this.store.dispatch(FoldersActions.replaceFolder({operation:operation}));
      this.result.emit({ success: true});
    });

    this.error$.pipe(
      filter(error => !!error), 
      take(1)
    ).subscribe(error => {
      console.error('Error occurred:', error);
      this.result.emit({ success: false, error: true });
    });
  }
}
