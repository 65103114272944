<div class="quantify-container">
    <div class="quantify-header d-flex justify-content-between">
        <div class="quantify-title-container d-flex justify-content-between">
            <div class="qunatify-title">
                <em class="icon-new_quantify"></em>
                <label class="title">Quantify</label>
            </div>

            <div class="stats-container">
                <div class="stat-item si-border-bottm">
                    <div class="stat-label">
                        <img src="./../../../assets/caculation_icon.PNG.png" alt="">
                        <label  >Calculations:</label>
                        <em class="icon-info"  triggers="hover" placement="bottom"></em>
                    </div>
                    <div class="stat-action">
                        <label class="value">111</label>
                        <button class="operation" >REQUEST</button>
                    </div>
                    <ng-template #popupContent1>
                        <div class="pop-container" style="max-width: 438px !important;">
                            <div class="pop-header d-flex" style="padding-left: 10px;">
                                <em class="icon-info bigger-info"></em>
                                <label class="pop-title" style="font: normal normal bold 20px/30px Nunito Sans, sans-serif !important;">
                                    Calculations
                                </label>
                            </div>
                            <p style="text-align: left;
                            font: normal normal bold 16px/26px Nunito Sans, sans-serif;
                            letter-spacing: 0px;
                            color: #123B68;
                            opacity: 1;padding-left: 10px;">Description:</p>
                            <p class="info-description" style="width:372px ;">
                                This section shows how many calculations that are available for the ongoing month. Every
                                following month, the number of calculations will be replenished in relation to the
                                purchased subscription plan.
                            </p>
                            <p class="info-description" style="width:372px; margin-top: -30px;">
                                Need more calculations?
                            </p>
                            <p class="info-description" style="width:372px; margin-top: -30px;">
                                Calculations may be requested for either single use purposes or for monthly calculation
                                subscription plan upgrade to suit your needs in either stand-alone projects or monthly
                                calculations upgrade.
                                Simply press REQUEST and we will get back to you right away.
                            </p>
                        </div>
                    </ng-template>
                </div>
                <div class="stat-item si-border-bottm">
                    <div class="stat-label">
                        <img src="./../../../assets//Engineering_consultations.PNG.png" alt="">
                        <label >Engineering Assistance:</label>
                        <em class="icon-info"  triggers="hover" placement="bottom-right"></em>
                    </div>
                    <div class="stat-action">
                        <label class="value">24</label>
                        <button class="operation" >REQUEST</button>
                    </div>
                    <ng-template #popupContent2>
                        <div class="pop-container" style="max-width: 438px !important;">
                            <div class="pop-header d-flex" style="padding-left: 10px;">
                                <em class="icon-info bigger-info"></em>
                                <label class="pop-title" style="font: normal normal bold 20px/30px Nunito Sans, sans-serif !important;">
                                    Engineering Assistance
                                </label>
                            </div>
                            <p style="text-align: left;
                                font: normal normal bold 16px/26px Nunito Sans, sans-serif;
                                letter-spacing: 0px;
                                color: #123B68;
                                opacity: 1;padding-left: 10px;">Description:</p>
                            <p class="info-description" style="width:372px ;">
                                This section shows how many engineering assistance hours that are available in total.
                                Engineering assistance hours are based on engineering consultancy or advisory from our
                                talented Environmental Engineering team related to technical platform assistance,
                                project & data support, supplier & sub-supplier assistance, life cycle assessment
                                support, circular economy advisory, and more.
                                In need of engineering assistance?
                                Engineering assistance may be requested for any type of project or problem that may
                                arise using the platform. If in doubt, feel free to use our live hotline in the
                                platform.
                                Simply press REQUEST and we will get back to you right away.
                            </p>
                            <p class="info-description" style="width:372px;margin-top: -30px;">
                                In need of engineering assistance?
                            </p>
                            <p class="info-description" style="width:372px;margin-top: -30px;">
                                Engineering assistance may be requested for any type of project or problem that may
                                arise using the platform. If in doubt, feel free to use our live hotline in the
                                platform.
                                Simply press REQUEST and we will get back to you right away.
                            </p>
                        </div>
                    </ng-template>
                </div>
                <div class="stat-item">
                    <div class="stat-label">
                        <img src="./../../../assets/Validation.SVG.png" alt="">
                        <label >Validation:</label>
                        <em  triggers="hover" placement="bottom" class="icon-info"></em>
                    </div>
                    <div class="stat-action">
                        <label class="value">22</label>
                        <button class="operation" >REQUEST</button>
                    </div>
                    <ng-template #popupContent>
                        <div class="pop-container" style="max-width: 438px !important;">
                            <div class="pop-header d-flex" style="padding-left: 10px;">
                                <em class="icon-info bigger-info"></em>
                                <label class="pop-title" style="font: normal normal bold 20px/30px Nunito Sans, sans-serif !important;">
                                    Validations
                                </label>
                            </div>
                            <p style="text-align: left;
                            font: normal normal bold 16px/26px Nunito Sans, sans-serif;
                            letter-spacing: 0px;
                            color: #123B68;
                            opacity: 1;padding-left: 10px;">Description:</p>
                            <p class="info-description" style="width:372px ;">
                                This section shows how many pre-paid validations that are available in total. Validation
                                is a critical review of the assessment by our engineering team to check if the
                                assessment aligns with the scientific practises of life cycle assessment and if it
                                correlates with the life cycle thinking. By validating your assessments, you ensure the
                                credibility of your calculations.
                            </p>
                            <p class="info-description" style="width:372px;margin-top: -30px;">
                                In need of assessment validation?
                            </p>
                            <p class="info-description" style="width:372px;margin-top: -30px;">
                                Validation may be requested for any type of completed projects in the platform.
                                Assessment shared with you may only be validated by the project owner.
                                Simply press REQUEST and we will get back to you right away.
                            </p>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>

    </div>




    <div class="projects-shortcut">
        <div class="new-folder" (click)="toggleUpsertFolder('create-folder')">
            <label class="title">New Folder</label>
            <img src="../../../assets/new_folder@2x.png" alt="" class="icon">
        </div>
        <hr class="vr">
        <div class="new-folder centered" (click)="createScreening()">
            <label class="title">New project</label>
            <img src="../../../assets/new_project@2x.png" alt="" class="icon icon2">
        </div>
        <hr class="vr">
        <div class="new-folder centered" (click)="toggleImportProject()">
            <label class="title" style="width: 110px;">Import Project</label>
            <img src="../../../assets/import_project.png" alt="" class="icon icon2" style="width: 83px !important;">
        </div>
        <hr class="vr">
        <div class="recent-projects-container">
            <label class="title">Recent Projects</label>
            <div class="recent-projects" >
                <ng-container  *ngFor="let recentProject of recentProjects$ | async">
                    <div class="recent-project" [routerLink]="['./screening',recentProject.id]">
                        <img src="../../../assets/rp_default@2x.png" alt="" class="main-project">
                        <img src="../../../assets/rp_hover@2x.png" alt="" class="over-project d-none">
                        <p class="project-name">
                            {{recentProject.name}}
                        </p>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="quantify-projects">
        <div class="projects-tree">
            <app-quantify-search [companyId]="companyId$ | async"></app-quantify-search>

            <ng-container *ngIf="showMovingFolder$ | async as showMoveFolder">
                <app-move-folder *ngIf="showMoveFolder==true" [selectedProject]="selectedProject$ | async"></app-move-folder>
            </ng-container>

            <div class="tree-container d-flex">
                <div class="folders-container">
                    <div class="folders-category-container">
                        <ng-container *ngIf="draftFolders$ | async as draftFolders">
                            <app-folder-node *ngIf="draftFolders.length>0;else " [rootFolder]="'Draft'" [flatTree]="draftFolders" [canSelectTargetFolder]="showMovingFolder$ | async"></app-folder-node>
                        </ng-container>
                        <app-empty-folder-node *ngIf="(draftFolders$ | async)?.length === 0" [folderName]="'Draft'"></app-empty-folder-node>

                        <ng-container *ngIf="completedFolders$ | async as completedFolders">
                            <app-folder-node *ngIf="completedFolders.length>0" [rootFolder]="'Completed'" [flatTree]="completedFolders" [canSelectTargetFolder]="showMovingFolder$ | async"></app-folder-node>
                        </ng-container>
                        <app-empty-folder-node *ngIf="(completedFolders$ | async)?.length === 0" [folderName]="'Completed'"></app-empty-folder-node>

                        <ng-container *ngIf="archivedFolders$ | async as archivedFolders">
                            <app-folder-node *ngIf="archivedFolders.length>0" [rootFolder]="'Archived'" [flatTree]="archivedFolders" [canSelectTargetFolder]="showMovingFolder$ | async"></app-folder-node>
                        </ng-container>
                        <app-empty-folder-node *ngIf="(archivedFolders$ | async)?.length === 0" [folderName]="'Archived'"></app-empty-folder-node>
                        
                    </div>


                </div>
                <div class="empty-container"></div>
                <div class="projects-container" >
                    <span *ngIf="lastfoldertDispatched$ | async as lastfoldertDispatched">
                        <app-projects-panel *ngIf="lastfoldertDispatched!=null" [sourceFolder]="lastfoldertDispatched" [companyId]="companyId" [rootOfLastSelectedNode]="rootOfLastSelectedNode$|async"></app-projects-panel>
                    </span>
                </div>
            </div>
        </div>
        <div class="project-details">
            <app-project-details></app-project-details>
        </div>
    </div>


</div>  

<app-upsert-folder-modal *ngIf="showUpsertFolderModal$ | async" [companyId]="companyId" (result)="upsertFolderResult($event)"></app-upsert-folder-modal>
<app-archive-manager-modal *ngIf="showArchiveModal$ | async" (result)="archiveResult($event)"></app-archive-manager-modal>
<app-move-project-modal *ngIf="showMoveProjectModal$|async" (result)="moveResult($event)"></app-move-project-modal>
<app-project-validation-modal *ngIf="showValidationModal$ | async" [currentProject]="selectedProject$ | async" [user]="user$|async"></app-project-validation-modal>
<app-import-project-modal *ngIf="showImportProject$|async" [companyId]="companyId"></app-import-project-modal>
<app-share-project-modal *ngIf="showShareModal$|async" [currentProject]="resultProject$ | async"></app-share-project-modal>
<!-- <app-refill-modal>
</app-refill-modal> -->