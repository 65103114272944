    <!-- Modal -->
    <div class="modalDialog" *ngIf="isVisible">
        <div class="re-modal-content">
            <div class="header-bottons">
                <div class="edit-botton-bloc">
                    <img src="../../../assets/danish-shipping-assets/edit-modal-icon.svg" alt="" class="edit-modal-icon">
                </div>
                <img src="../../../assets/danish-shipping-assets/close-modal-icon.svg" alt="" class="close-modal-icon" (click)="close()">
            </div>
            <div class="modal-title-description">
                <label class="mdl-title">Please provide a name for the session</label>
                <p class="mdl-description">
                    The results of each custom scenario will be available in the 'Results Overview,' and can be easily located by the assigned session name.
                </p>
            </div>
            <div class="modal-input-bloc">
                <label class="input-lbl-txt">Session name</label>
                <input 
                type="text" 
                class="input-custom-content" 
                name="session_name"
                [(ngModel)]="sessionName" 
                placeholder="e.g. Custom session">
            
            </div>
          <div class="mdl-actions">
              <button class="cancel" (click)="close()">Cancel</button>
            <button class="confirm" (click)="confirm()">Confirm</button>
          </div>
        </div>
      </div>
        
