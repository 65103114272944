import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '@auth0/auth0-angular';
import { ProjectsState } from '../reducers/project.reducer';
import { FoldersState } from '../reducers/folder.reducer';

export const selectFoldersState = createFeatureSelector<AppState,FoldersState>('folders');

export const selectDraftFolders = createSelector(
    selectFoldersState,
    (state: FoldersState) => state.draftFolders
);

export const selectCompetedFolders = createSelector(
    selectFoldersState,
    (state: FoldersState) => state.completedFolders
);
export const selectArchivedFolders = createSelector(
    selectFoldersState,
    (state: FoldersState) => state.archivedFolders
);

export const selectParentId = createSelector(
    selectFoldersState,
    (state: FoldersState) => state.upsertFolder.targetParentId
);

export const selectIdCompleted = createSelector(
    selectFoldersState,
    (state: FoldersState) => state.upsertFolder.completed
);


export const selectOldFolderName = createSelector(
    selectFoldersState,
    (state: FoldersState) => state.upsertFolder.oldFolderName
);


export const selectModalType = createSelector(
    selectFoldersState,
    (state: FoldersState) => state.upsertFolder.modalType
);

export const selectSuccessMessage = createSelector(
    selectFoldersState,
    (state: FoldersState) => state.success
);


export const selectResult = createSelector(
    selectFoldersState,
    (state: FoldersState) => state.result
);

export const selectError = createSelector(
    selectFoldersState,
    (state: FoldersState) => state.error
);


export const selectCanShowArchiveModal = createSelector(
    selectFoldersState,
    (state: FoldersState) => state.archiveModal.canShowArchiveModal
);

export const selectArchiveFolderName = createSelector(
    selectFoldersState,
    (state: FoldersState) => state.archiveModal.folderName
);

export const selectArchiveFolderId = createSelector(
    selectFoldersState,
    (state: FoldersState) => state.archiveModal.targetId
);

export const selectArchiveOperation = createSelector(
    selectFoldersState,
    (state: FoldersState) => state.archiveModal.archiveOperation
);

export const selectArchiveModaalData = createSelector(
    selectFoldersState,
    (state: FoldersState) => state.archiveModal
);

export const selectsLastfoldertDispatched = createSelector(
    selectFoldersState,
    (state: FoldersState) => state.lastProjectsDispatcher
);


export const selectsRootOfLastSelectedNode = createSelector(
    selectFoldersState,
    (state: FoldersState) => state.rootOfLastSelectedNode
);

export const selectsLastSelectedNode = createSelector(
    selectFoldersState,
    (state: FoldersState) => state.lastSelectedNode
);

