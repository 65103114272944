<div class="results-overview">
    <div class="tab-container">
        <div class="tab active">Completed</div>
        <div class="tab">Draft</div>
        <div class="tab">Archived</div>
    </div>
    <div class="active-tab">
        <div class="table-container">
            <div class="table-header">
                <div class="table-header-cell">Session Name</div>
                <div class="table-header-cell">Type</div>
                <div class="table-header-cell">Date</div>
                <div class="table-header-cell">Status</div>
            </div>
        
            <div class="table-row">
                <div class="table-cell">
                    <div class="text-table-cell">Sell Vessel 28/09/2023</div>
                    <div class="supporting-text-table-cell"></div>
                </div>
                <div class="table-cell">
                    <span class="badge blue-badge">Renewable Energy in Assembly</span>
                </div>
                <div class="table-cell">
                    <div class="text-table-cell">22/06/2024</div>
                </div>
                <div class="table-cell">
                    <button class="text-button archive-button">Archive</button>
                    <button class="text-button view-button">View</button>
                </div>
            </div>
        
            <div class="table-row">
                <div class="table-cell">
                    <div class="text-table-cell">Test session for Vessel 895467583</div>
                    <div class="supporting-text-table-cell"></div>
                </div>
                <div class="table-cell">
                    <span class="badge gray-blue-badge">Vessel Recycling</span>
                </div>
                <div class="table-cell">
                    <div class="text-table-cell">22/06/2024</div>
                </div>
                <div class="table-cell">
                    <button class="text-button archive-button">Archive</button>
                    <button class="text-button view-button">View</button>
                </div>
            </div>
        
            <div class="table-row">
                <div class="table-cell">
                    <div class="text-table-cell">Very long Project Name with more than one two three words, one line, two lines, three lines...</div>
                    <div class="supporting-text-table-cell"></div>
                </div>
                <div class="table-cell">
                    <span class="badge indigo-badge">Retrofit: Modifications and Upgrades</span>
                </div>
                <div class="table-cell">
                    <div class="text-table-cell">22/06/2024</div>
                </div>
                <div class="table-cell">
                    <button class="text-button archive-button">Archive</button>
                    <button class="text-button view-button">View</button>
                </div>
            </div>
            <div class="pagination">
                <div class="pagination-button">
                    <button class="pagination-nav-button">
                        <span class="pagination-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M15.8327 10.0013H4.16602M4.16602 10.0013L9.99935 15.8346M4.16602 10.0013L9.99935 4.16797" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </span>
                        <span class="pagination-text">Previous</span>
                    </button>
                </div>
        
                <div class="pagination-numbers">
                    <button class="pagination-number active">1</button>
                    <button class="pagination-number">2</button>
                    <button class="pagination-number">3</button>
                    <button class="pagination-number">...</button>
                    <button class="pagination-number">8</button>
                    <button class="pagination-number">9</button>
                    <button class="pagination-number">10</button>
                </div>
        
                <div class="pagination-button">
                    <button class="pagination-nav-button">
                        <span class="pagination-text">Next</span>
                        <span class="pagination-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M4.16602 10.0013H15.8327M15.8327 10.0013L9.99935 4.16797M15.8327 10.0013L9.99935 15.8346" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </span>
                    </button>
                </div>
            </div>
        </div>
        
    </div>
</div>