import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-session-modal',
  templateUrl: './session-modal.component.html',
  styleUrls: ['./session-modal.component.scss']
})
export class SessionModalComponent implements OnInit {
  @Input() isVisible: boolean = false;
  @Output() closeEvent = new EventEmitter<void>();
  @Output() confirmEvent = new EventEmitter<string>();
  sessionName: string = '';

  constructor(private router: Router) {}

  ngOnInit(): void {}

  close(): void {
    this.closeEvent.emit();
  }

  confirm(): void {
    if (this.sessionName.trim()) {
      this.confirmEvent.emit(this.sessionName);
      this.close();
      
   
    } else {
      alert('Please enter a session name.');
    }
  }
}
