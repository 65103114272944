import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms'; 
import { QuantifyRoutingModule } from './quantify-routing.module';
import { QuantifyComponent } from './quantify.component';
import { QuantifyCalculatorComponent } from './quantify-calculator/quantify-calculator.component';
import { UpsertFolderModalComponent } from './modals/upsert-folder-modal/upsert-folder-modal.component';
import { StoreModule } from '@ngrx/store';
import { FolderReducer } from './store/reducers/folder.reducer';
import {FormsModule} from '@angular/forms'
import { EffectsModule } from '@ngrx/effects';
import { FolderEffects } from './store/effects/folder.effects';
import { ProjectReducer } from './store/reducers/project.reducer';
import { QuantifySearchComponent } from './components/quantify-search/quantify-search.component';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FolderNodeComponent } from './components/folder-node/folder-node.component';
import { ArchiveManagerModalComponent } from './modals/archive-manager-modal/archive-manager-modal.component';
import { EmptyFolderNodeComponent } from './components/empty-folder-node/empty-folder-node.component';
import { ProjectsPanelComponent } from './components/projects-panel/projects-panel.component';
import { ProjectDetailsComponent } from './components/project-details/project-details.component';
import { MoveFolderComponent } from './components/move-folder/move-folder.component';
import { ArchivedDetailsComponent } from './components/project-details/archived-details/archived-details.component';
import { DraftDetailsComponent } from './components/project-details/draft-details/draft-details.component';
import { CompletedDetailsComponent } from './components/project-details/completed-details/completed-details.component';
import { MoveProjectModalComponent } from './modals/move-project-modal/move-project-modal.component';
import { ProjectValidationModalComponent } from './modals/project-validation-modal/project-validation-modal.component';
import { ImportProjectModalComponent } from './modals/import-project-modal/import-project-modal.component';
import { ShareProjectModalComponent } from './modals/share-project-modal/share-project-modal.component';
import { ClipboardModule } from '@angular/cdk/clipboard'
import { EditScreeningComponent } from "./edit-screening/edit-screening.component";
import { ScreeningSetupComponent } from "./edit-screening/screening-setup/screening-setup.component";
import { SystemDefinitionComponent } from "./edit-screening/system-definition/system-definition.component";
import { ScreeningInventoryComponent } from "./edit-screening/screening-inventory/screening-inventory.component";
import { ResultSummaryComponent } from "./edit-screening/result-summary/result-summary.component";
import { MaterialModalComponent } from "./edit-screening/screening-inventory/material-modal/material-modal.component";
import { ScreeningReviewComponent } from "./edit-screening/screening-review/screening-review.component";
import { LifeCycleModalComponent } from "./edit-screening/system-definition/life-cycle-modal/life-cycle-modal.component";
import { SubmitCorrectionModalComponent } from "./edit-screening/result-summary/submit-correction-modal/submit-correction-modal.component";
import { LifespanModalComponent } from "./edit-screening/screening-inventory/lifespan-modal/lifespan-modal.component";
import { RefillModalComponent } from "./refill-modal/refill-modal.component";
import {
  CdkDragDrop,
  DragDropModule,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { FilterPipePipe } from '../../pipes/filter-pipe.pipe';
import { SharedModule } from '../../shared/shared.module';
import { ValidationRequestModalComponent } from './validation-request-modal/validation-request-modal.component';

@NgModule({
  declarations: [
    QuantifyComponent, 
    QuantifyCalculatorComponent, 
    UpsertFolderModalComponent, 
    QuantifySearchComponent, 
    FolderNodeComponent, 
    ArchiveManagerModalComponent, 
    EmptyFolderNodeComponent, 
    ProjectsPanelComponent, 
    ProjectDetailsComponent, 
    MoveFolderComponent, 
    ArchivedDetailsComponent,
    DraftDetailsComponent, 
    CompletedDetailsComponent, 
    MoveProjectModalComponent, 
    ProjectValidationModalComponent, 
    ImportProjectModalComponent, 
    ShareProjectModalComponent,
    EditScreeningComponent,
    ScreeningSetupComponent,
    SystemDefinitionComponent,
    ScreeningInventoryComponent,
    ResultSummaryComponent,
    MaterialModalComponent,
    ScreeningReviewComponent,
    LifeCycleModalComponent,
    SubmitCorrectionModalComponent,
    LifespanModalComponent,
    RefillModalComponent,
    ValidationRequestModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    QuantifyRoutingModule,
    NgbModule,
    ClipboardModule,
    DragDropModule,
    ReactiveFormsModule,
    SharedModule
    //EffectsModule.forRoot([])
  ]
})
export class QuantifyModule { }
