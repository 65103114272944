<div class="scenario-container">
    <!-- Navigation Tabs -->
    <div class="tabs">
      <div class="tab-container" >
        <div class="tab" [ngClass]="{'active': activeTabIndex === 0}">
          <span class="tab-number" [ngClass]="{'checked': activeTabIndex > 0}">
            <img *ngIf="activeTabIndex > 0" src="../../../assets/danish-shipping-assets/check-icon.svg" class="check-icon">
            <span *ngIf="activeTabIndex === 0">
              <span class="circle"></span>
            </span>
            <span *ngIf="activeTabIndex !== 0 && activeTabIndex <= 0">1</span>
          </span>
          <label>Baseline building scenario</label>
        </div>
      </div>
      <div class="tab-container" >
        <div class="tab" [ngClass]="{'active': activeTabIndex === 1}">
          <span class="tab-number" [ngClass]="{'checked': activeTabIndex > 1}">
            <img *ngIf="activeTabIndex > 1" src="../../../assets/danish-shipping-assets/check-icon.svg" class="check-icon">
            <span *ngIf="activeTabIndex === 1">
              <span class="circle"></span>
            </span>
            <span *ngIf="activeTabIndex !== 1 && activeTabIndex <= 1">2</span>
          </span>
          <label>Fuel Consumption and Type</label>
        </div>
      </div>
      <div class="tab-container" >
        <div class="tab" [ngClass]="{'active': activeTabIndex === 2}">
          <span class="tab-number" [ngClass]="{'checked': activeTabIndex > 2}">
            <img *ngIf="activeTabIndex > 2" src="../../../assets/danish-shipping-assets/check-icon.svg" class="check-icon">
            <span *ngIf="activeTabIndex === 2">
              <span class="circle"></span>
            </span>
            <span *ngIf="activeTabIndex !== 2 && activeTabIndex <= 2">3</span>
          </span>
          <label>Recycling Method and Country</label>
        </div>
      </div>
    </div>
  
    <div *ngIf="activeTabIndex === 0" class="cards-section">
        <!-- Content for Tab 1 -->
      <div class="card">
        <div class="card-title">
          <label class="main-title">Vessel Type</label>

            <div class="card-value-block">
                <img src="../../../assets/danish-shipping-assets/ship-dark-icon.svg" alt="" class="">
                <label for="" class="card-value">Cargo</label>
            </div>
        </div>
      
        <p class="card-description">
          The vessel type is such as container ship, tanker, or bulk carrier, which determines the specific parameters and assumptions used in the lifecycle screening.
        </p>
      </div>
      <div class="card">
        <div class="card-title">
          <label class="main-title">DWT</label>

            <div class="card-value-block">
                <img src="../../../assets/danish-shipping-assets/file-dark-icon.svg" alt="" class="">
                <label for="" class="card-value">65,748 tonnes</label>
            </div>
        </div>
      
        <p class="card-description">
            The vessel's Deadweight Tonnage (DWT) is the total weight capacity used as the basis for lifecycle screening calculations and assumptions.
        </p>
      </div>
      <div class="card">
        <div class="card-title">
          <label class="main-title">Built location</label>

            <div class="card-value-block">
                <img src="../../../assets/danish-shipping-assets/location-dark-icon.svg" alt="" class="">
                <label for="" class="card-value">Ukraine</label>
            </div>
        </div>
      
        <p class="card-description">
            The geographical location of the shipyard where the vessel is constructed influences the climate impact factors in the lifecycle screening.
        </p>
      </div>
      <div class="card">
        <div class="card-title">
          <label class="main-title">Length</label>

            <div class="card-value-block">
                <img src="../../../assets/danish-shipping-assets/ruler-dark-icon.svg" alt="" class="">
                <label for="" class="card-value">657 M</label>
            </div>
        </div>
      
        <p class="card-description">
            The size information of the vessel is including Length (L) and Beam (B), which are fundamental for defining the vessel’s structural properties in the lifecycle screening.
        </p>
      </div>
      <div class="card">
        <div class="card-title">
          <label class="main-title">Beam</label>

            <div class="card-value-block">
                <img src="../../../assets/danish-shipping-assets/ruler-dark-icon.svg" alt="" class="">
                <label for="" class="card-value">65 M</label>
            </div>
        </div>
      
        <p class="card-description">
            The size information of the vessel is including Length (L) and Beam (B), which are fundamental for defining the vessel’s structural properties in the lifecycle screening.
        </p>
      </div>
  
    
    </div>


 
  <div *ngIf="activeTabIndex === 1" class="cards-section">
   
           <!-- Content for Tab 2 --> 
           <div class="empty-state-block">
            
            <div class="empty-state-description-block">
              <div class="empty-state-txt-block">
                <label class="empty-state-title">To continue add Fuel Consumption and Type</label>
                <p class="empty-state-description">
                  Enter the type and amount of fuel used for the vessel, along with the amount of lubricating oil. These inputs are
                  needed for accurate lifecycle screening results, as they directly influence the emissions generated during the
                  vessel's operation.
                </p>
              </div>
              <div class="empty-state-add-option" (click)="openMaterialModal()">
                <img src="../../../assets/danish-shipping-assets/plus-icon.svg" alt="">
                <label for="">Add Option</label>
              </div>
            </div>
          </div>

          <div class="options-table-container">
            <table class="options-table">
              <thead>
                <tr>
                  <th class="option-header">Option</th>
                  <th class="weight-header">Total weight</th>
                  <th class="action-header"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Biomethanol</td>
                  <td class="add-weight">
                    <input type="text" class="input-weight" placeholder="Add weight">
                    <span class="unit">t</span>
                  </td>
                  <td class="actions">
                    <span class="delete-link">Delete</span>
                    <span class="edit-link">Edit</span>
                  </td>
                </tr>
                <tr *ngFor="let option of options">
                
                  <td class="select-oil">
                    <span class="select-oil-link">  <img src="../../../assets/danish-shipping-assets/plus-blue-icon.svg" alt=""> {{ option.type }}</span>
                  </td>
                  <td class="add-weight">
                    <input type="text" class="input-weight" placeholder="Add weight" [(ngModel)]="option.weight">
                    <div ngbDropdown>
                      <div class="dropdown-wrapper no-arrow" id="select-weight" ngbDropdownToggle>
                        <span class="drop-txt">{{ option.unit }}</span>
                        <img src="../../../../assets/danish-shipping-assets/drop-arrow-icon.svg" alt="" class="percentage-icon">
                      </div>
                      <div ngbDropdownMenu aria-labelledby="select-weight">
                        <div ngbDropdownItem (click)="option.unit = 't'">t</div>
                        <div ngbDropdownItem (click)="option.unit = 'm'">m</div>
                        <div ngbDropdownItem (click)="option.unit = 'kg'">kg</div>
                      </div>
                    </div>
                  </td>
                  <td class="actions">
                    <span class="edit-link">Edit</span>
                   
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="add-option-btn" (click)="addOption()">
              <img src="../../../assets/danish-shipping-assets/plus-gray-icon.svg" alt="" class="plus-icon">
              <label class="add-option-txt">Add Option</label>
            </div>

            <span class="consumption-note">Please add whole lifetime consumption </span>
          </div>
            
  </div>
  <div *ngIf="activeTabIndex === 2" class="cards-section">
    <!-- Content for Tab 3 -->

    <div class="empty-state-block">
            
      <div class="empty-state-description-block">
        <div class="empty-state-txt-block">
          <label class="empty-state-title">To continue please add Recycling Method and Country</label>
          <p class="empty-state-description">
            Choose the recycling method and the country where the recycling will take place. These selections will influence the lifecycle emissions calculations, as different methods and locations have varying climate impacts.
          </p>
        </div>
        <div class="empty-state-add-option">
          <img src="../../../assets/danish-shipping-assets/plus-icon.svg" alt="">
          <label for="">Add Option</label>
        </div>
      </div>
    </div>

    <div class="options-table-container">
      <table class="options-table">
        <thead>
          <tr>
            <th class="option-header">Method</th>
            <th class="weight-header">Country</th>
            <th class="action-header"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Oxy- fuel</td>
            <td class="add-weight">
              <span class="unit">India</span>
            </td>
            <td class="actions">
              <span class="delete-link">Delete</span>
              <span class="edit-link">Edit</span>
            </td>
          </tr>
        
        </tbody>
      </table>
 
    </div>
  </div>

  <!-- Buttons Section -->
  <div class="buttons-section">
    
   

    <div class="save-draft-btn" >
        <img src="../../../assets/danish-shipping-assets/arrow-left-dark-icon.svg" alt="" class="">
        <label for="">Save as draft</label>
        
    </div>
    <div class="next-btn" (click)="goToNextTab()">
        <label for="">{{ activeTabIndex === 2 ? 'Calculate' : 'Next' }}</label>
        <img  *ngIf="activeTabIndex !== 2"  src="../../../assets/danish-shipping-assets/arrow-right-light-icon.svg" alt="" class="">
    </div>
  </div>
  
 
  </div>