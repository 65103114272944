import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-material-modal',
  templateUrl: './material-modal.component.html',
  styleUrls: ['./material-modal.component.scss']
})
export class MaterialModalComponent implements OnInit {
  // State Variables
  public showSub: boolean = true;
  public showPrivate: boolean = false;
  public canShowEndGroups: boolean = true;
  public canShowSharedDetails: boolean = true;

  public subGroups: any[] = [];
  public privateSubGroup: any[] = [];
  public myPorjectsSubfolders: any[] = [];
  public locations: any[] = [];
  public endGroups: any[] = [];
  public searchResults: any[] = [];
  public sharedComponents: any[] = [];
  public selectedShared: any = null;
  public selectedItem: any = null;
  public selectedLocation: any = null;
  public currentCompany: any = null; // Fake company data
  public searchEGroup: string = ''; // Search input value
  public canShowDescription: boolean = false;
  public canShowAdd: boolean = false;

  @Input() public material: any = { name: '', weight: '', unit: '' };
  @Input() public title: string = 'Fuel Option';
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.populateFakeData();
  }

  // Populate the modal with fake data
  private populateFakeData(): void {
    // Fake company data
    this.currentCompany = {
      companyName: 'Company A'
    };

    // Fake subgroups
    this.subGroups = [
      { name: 'Subgroup 1' },
      { name: 'Subgroup 2' }
    ];

    // Fake private subgroups
    this.privateSubGroup = [
      { name: 'Private Subgroup 1' },
      { name: 'Private Subgroup 2' }
    ];

    // Fake project subfolders
    this.myPorjectsSubfolders = [
      { folderName: 'Folder 1' },
      { folderName: 'Folder 2' }
    ];

    // Fake end groups
    this.endGroups = [
      {
        name: 'End Group 1',
        processes: [
          { name: 'Process 1' },
          { name: 'Process 2' }
        ]
      },
      {
        name: 'End Group 2',
        processes: [
          { name: 'Process A' },
          { name: 'Process B' }
        ]
      }
    ];

    // Fake shared components
    this.sharedComponents = [
      {
        name: 'Shared Component 1',
        company: { companyName: 'Shared Company A' },
        note: 'Note 1',
        weight: 10,
        emission: 30.5
      },
      {
        name: 'Shared Component 2',
        company: { companyName: 'Shared Company B' },
        note: 'Note 2',
        weight: 15,
        emission: 45.2
      }
    ];

    // Fake locations
    this.locations = [
      { location: 'Location 1', id: 1 },
      { location: 'Location 2', id: 2 }
    ];

    // Fake selected material
    this.material = {
      name: 'Diesel Fuel',
      weight: 10,
      unit: 't'
    };
  }

  public toogleSubGroups(): void {
    this.showSub = !this.showSub;
    this.showPrivate = false; // Ensure private groups are hidden when sub-groups are toggled
  }

  public tooglePrivate(): void {
    this.showPrivate = !this.showPrivate;
    this.showSub = false; // Ensure sub-groups are hidden when private groups are toggled
  }

  public showEndGroups(subGroup: any): void {
    console.log('Selected subgroup:', subGroup);
    this.endGroups = subGroup.endGroups || [];
    this.canShowEndGroups = true;
    this.searchEGroup = ''; // Clear search input
  }

  public selectLocation(location: any): void {
    console.log('Selected location:', location);
    this.selectedLocation = location;
    this.material.location = location.location;
    this.material.locationEmissionId = location.id;
    this.canShowAdd = true;
  }

  public close(): void {
    this.activeModal.close();
  }

  public addMaterial(): void {
    console.log('Material added:', this.material);
    this.passEntry.emit(this.material);
    this.activeModal.close(this.material);
  }
}
