<div class="selected-project-conainer">
    <ng-container *ngIf="currentProject$ | async as currentProject;else noproject">
        <div class="current-project-container" >
        
            






            <app-draft-details [currentProject]="currentProject" *ngIf="currentProject.archived==false&&currentProject.finished==false"></app-draft-details>
            <app-completed-details [currentProject]="currentProject" *ngIf="currentProject.archived==false&&currentProject.finished==true"></app-completed-details>
            <app-archived-details [currentProject]="currentProject" *ngIf="currentProject.archived==true"></app-archived-details>














        </div>
    </ng-container>
    <ng-template #noproject>
        <div class="no-selected-project" *ngIf="!(loadingResultProject$|async)">
            <img src="../../../../../assets/no_project_selected.svg" alt="" class="not-selected-image"/>
            <label class="not-selected-message">Please select a project for more details</label>
        </div>
    </ng-template>
    <div class="loading-indicator" *ngIf="loadingResultProject$|async">
        <div class="spinner-border text-secondary"></div>
        <label for="">Loading project details..</label>
    </div>
</div>