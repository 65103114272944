import { createAction, props } from '@ngrx/store';

export const loadCompanyId = createAction("[App init] load companyId",props<{id:any}>());
export const registerCurrentUser = createAction("[App init] register current User",props<{user:any}>());
export const registerUserInfo = createAction("[Side menu init] register user info",props<{user:any}>());


export const UserActions={
    loadCompanyId,
    registerCurrentUser,
    registerUserInfo

}