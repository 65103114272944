import { createReducer, on } from '@ngrx/store';
import { VesselsOverviewActions } from '../actions/vessels-overview.actions';


export interface VesselsOverviewState {
    vesselToUpsert:any,
    lastArchivedVessel:any,
    lastRestoredVessel:any,
    activeStats:any,
    archivedStats:any,
    newVesselData:any,
    createdVessel:any,

    activeVessels:Array<any>,
    archivedVessels:Array<any>,

    viewType:any,
    selectedTab:any,

    canShowVesselDrawer:boolean,
    loadingActive:boolean,
    loadingArchived:boolean,
    error:any,
}
  
const initialState: VesselsOverviewState = {
    vesselToUpsert:{},
    lastArchivedVessel:null,
    lastRestoredVessel:null,
    activeStats:null,
    archivedStats:null,
    newVesselData:null,
    createdVessel:null,

    activeVessels:[],
    archivedVessels:[],

    viewType:"grid",
    selectedTab:"active",

    canShowVesselDrawer:false,
    loadingActive:false,
    loadingArchived:false,
    error:null
};

export const VesselsOverviewReducer = createReducer(
    initialState,
    on(VesselsOverviewActions.toggleVesselDrawer,  (state, { canShow }) => ({
        ...state, 
        canShowVesselDrawer: canShow
    })),
    on(VesselsOverviewActions.toggleView,  (state, { viewType }) => ({
        ...state, 
        viewType: viewType
    })),
    on(VesselsOverviewActions.toggleSelectedTab,  (state, { selectedTab }) => ({
        ...state, 
        selectedTab: selectedTab
    })),


    on(VesselsOverviewActions.getActiveVesselSuccess,  (state, { vessels }) => ({
        ...state, 
        activeVessels: vessels,
        lastArchivedVessel:null,
        loadingActive:false,
        error: null
    })),
    on(VesselsOverviewActions.archiveVessel,  (state, { vessel }) => ({
        ...state, 
        lastArchivedVessel:null,
        error: null
    })),
    on(VesselsOverviewActions.archiveVesselSuccess,  (state, { archivedVessel }) => ({
        ...state, 
        activeVessels: state.activeVessels.filter(x=>x.id!=archivedVessel.id),
        archivedVessels: [...state.archivedVessels,archivedVessel],
        lastArchivedVessel: archivedVessel,
        error: null
    })),
    on(VesselsOverviewActions.archiveVesselFailed,  (state, { error }) => ({
        ...state, 
        lastArchivedVessel:null,
        error:"archive vessel failed"
    })),


    on(VesselsOverviewActions.getArchivedVesselSuccess,  (state, { vessels }) => ({
        ...state, 
        archivedVessels: vessels,
        lastRestoredVessel:null,
        loadingArchived:false,
        error: null
    })),
    on(VesselsOverviewActions.restoreVessel,  (state, { vessel }) => ({
        ...state, 
        lastRestoredVessel:null,
        error: null
    })),
    on(VesselsOverviewActions.restoreVesselSuccess,  (state, { restoredVessel }) => ({
        ...state, 
        archivedVessels: state.archivedVessels.filter(x=>x.id!=restoredVessel.id),
        activeVessels: [...state.activeVessels,restoredVessel],
        lastRestoredVessel: restoredVessel,
        error: null
    })),
    on(VesselsOverviewActions.restoreVesselFailed,  (state, { error }) => ({
        ...state, 
        lastRestoredVessel:null,
        error:"restore failed"
    })),


    on(VesselsOverviewActions.getActiveStatsSuccess,  (state, { stats }) => ({
        ...state, 
        activeStats:stats,
        error: null
    })),
    on(VesselsOverviewActions.getActiveStatsFailed,  (state, { error }) => ({
        ...state, 
        error:"get stats failed"
    })),

    on(VesselsOverviewActions.getArchivedStatsSuccess,  (state, { stats }) => ({
        ...state, 
        archivedStats:stats,
        error: null
    })),
    on(VesselsOverviewActions.getArchivedStatsFailed,  (state, { error }) => ({
        ...state, 
         error:"get stats failed"
    })),

    on(VesselsOverviewActions.importVesselData,  (state, { imo }) => ({
        ...state, 
        newVesselData:null,
        error: null
    })),
    on(VesselsOverviewActions.importVesselDataSuccess,  (state, { newVesselData }) => ({
        ...state, 
        newVesselData:newVesselData,
        error: null
    })),
    on(VesselsOverviewActions.importVesselDataFailed,  (state, { error }) => ({
        ...state, 
        newVesselData:null,
        error:"import vessel failed"
    })),



    on(VesselsOverviewActions.createVessel,  (state, { vessel }) => ({
        ...state, 
        createdVessel:null,
        error: null
    })),
    on(VesselsOverviewActions.createVesselSuccess,  (state, { createdVessel }) => ({
        ...state, 
        createdVessel:createdVessel,
        error: null
    })),
    on(VesselsOverviewActions.createVesselFailed,  (state, { error }) => ({
        ...state, 
        createdVessel:null,
        error:"create vessel failed"
    })),

    on(VesselsOverviewActions.toggleLoadingActive,  (state, { canShow }) => ({
        ...state, 
        loadingActive:canShow,
    })),
    on(VesselsOverviewActions.toggleLoadingArchived,  (state, { canShow }) => ({
        ...state, 
        loadingArchived:canShow,
    })),

);