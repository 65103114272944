import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { FoldersState } from '../../store/reducers/folder.reducer';
import { FoldersActions } from '../../store/actions/folder.actions';
import { ProjectsState } from '../../store/reducers/project.reducer';
import { ProjectsActions } from '../../store/actions/project.actions';
import { selectloadingCount, selectLoadingMore, selectsArchivedProjects, selectsCompletedProjects, selectsDraftProjects, selectsSubFolderProjects } from '../../store/selectors/project.selectors';

@Component({
  selector: 'app-projects-panel',
  templateUrl: './projects-panel.component.html',
  styleUrls: ['./projects-panel.component.scss']
})
export class ProjectsPanelComponent implements  OnChanges {
  @Input() sourceFolder:any=null;
  @Input() companyId:any=null;
  @Input() rootOfLastSelectedNode:any=null;

  projects$: Observable<any>;
  isLoading$: Observable<any>;
  loadingCount$: Observable<any>;
  loadingCount:any=0;
  isLoading:boolean=false;
  selectedProject:any=null;
  lastFolderDispatcher:any="";
  hasMore = true;
  page = 0;
  pageSize = 10;
  sourceMap={
    Draft:'draft',
    Completed:'completed',
    Archived:'archived',
  }


  private scrollSubject = new Subject<Event>();
  private destroy$ = new Subject<void>();

  constructor(
    private projectsStore: Store<{ folders: ProjectsState }>,
  ) {
    this.isLoading$=this.projectsStore.select(selectLoadingMore);
    this.loadingCount$=this.projectsStore.select(selectloadingCount);
   
   }

  ngOnChanges(): void {
    this.loadingCount$.subscribe(count => {
      this.loadingCount = count;
    });
    this.isLoading = false; // Reset loading state for new data
    this.page = 0;

    if (this.sourceFolder.id == 0) {
      switch (this.sourceFolder.folderName) {
        case 'Draft':
          this.projects$ = this.projectsStore.select(selectsDraftProjects);
          this.lastFolderDispatcher="draftProjects";
          break;
        case 'Completed':
          this.projects$ = this.projectsStore.select(selectsCompletedProjects);
          this.lastFolderDispatcher="completedProjects";
          break;
        case 'Archived':
          this.projects$ = this.projectsStore.select(selectsArchivedProjects);
          this.lastFolderDispatcher="archivedProjects";
          break;
        default:
          break;
      }
    }else{
      this.projectsStore.dispatch(ProjectsActions.clearSubfolderProjects())
      this.projects$ = this.projectsStore.select(selectsSubFolderProjects);
      this.lastFolderDispatcher="subfolderProjects";
    }
    this.loadMore();
    this.projects$.subscribe(ans=>{
      if(ans.length==0){
      }
    })
    
    //this.projects$ = Array.from({ length: 10 }, (_, i) => `Initial Item ${i + 1}`);
    this.scrollSubject
    .pipe(debounceTime(200), takeUntil(this.destroy$))
    .subscribe(event => this.handleScroll(event));
  }

  selectProject(project){
    this.selectedProject=project;
    this.projectsStore.dispatch(ProjectsActions.selectedProject({project:{
      id:project.id,
      name:project.name,
    }}))
    this.projectsStore.dispatch(ProjectsActions.fetchSelectedProject({project:this.selectedProject}))
  }
  
  loadMore(): void {
    if (this.isLoading || !this.hasMore) {
      return; // Prevent additional requests if one is already in progress or there's no more data
    }
  
    this.isLoading = true; // Set flag to indicate a request is in progress
    if(this.sourceFolder.folderName==""){
      return
    }

    if (this.sourceFolder.id==0) {
      
      this.projectsStore.dispatch(
        ProjectsActions.loadMore({
          getScreeningsByStatusDto: {
            companyId: this.companyId,
            status: this.sourceMap[this.sourceFolder.folderName],
            loadingCount: this.loadingCount,
          },
        })
      );
    }else{
      this.projectsStore.dispatch(
        ProjectsActions.loadMoreOfSubfolder({
          getScreeningByFolderDto: {
            folderId: this.sourceFolder.id,
            loadingCount: this.loadingCount,
            isArchived: this.rootOfLastSelectedNode=="Archived",
          },
        })
      );
    }
    this.isLoading$ .subscribe((loading) => {
      this.isLoading = loading;
    });
  
    this.page++;
  }


  onScroll(event: Event): void {
    this.scrollSubject.next(event);
  }

  // Handle debounced scroll events
  handleScroll(event: Event): void {
    const element = event.target as HTMLElement;
  
    if (
      element.scrollHeight - element.scrollTop <= element.clientHeight + 50 &&
      !this.isLoading &&
      this.hasMore
    ) {
      this.loadMore();
    }
  }

  ngOnDestroy(): void {
    // Cleanup subscriptions
    this.destroy$.next();
    this.destroy$.complete();
  }

}
