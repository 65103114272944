import { createReducer, on } from '@ngrx/store';
import { FoldersActions } from '../actions/folder.actions';
import { UpsertFolderProps } from '../models/upsert-folder-props';
import { Folder } from '../models/folder';

export interface FoldersState {
    upsertFolder:any,
    archiveModal:any,
    completedFolders: Array<any>,
    draftFolders: Array<any>,
    archivedFolders: Array<any>,
    sharedFolders: Array<any>,
    loadingcompleted: boolean | null;
    loadingdraft: boolean | null;
    loadingarchived: boolean | null;
    lastSelectedNode:any,
    rootOfLastSelectedNode:any,
    lastProjectsDispatcher:any,
    loading: boolean | null;
    error: any | null;
    success:any,
    trackChanges:boolean,
    result:any
}
  
const initialState: FoldersState = {

    upsertFolder:{
        modalType:null,
        oldFolderId:null,
        oldFolderName:null,
        targetParentId:null,
        canShowUpsert:false,
        completed:null
    },
    archiveModal:{
        canShowArchiveModal:false,
        archiveOperation:'',
        folderName:'',
        folderId:null,
        targetType:null,
        source:''
    },

    lastProjectsDispatcher:null,
    
    result:null,
    lastSelectedNode:null,
    rootOfLastSelectedNode:null,
    

    completedFolders: [],
    loadingcompleted:false,

    draftFolders: [],
    loadingdraft:false,

    archivedFolders: [],
    loadingarchived:false,

    sharedFolders: [],
    loading: null,
    error:null,
    success:'',
    trackChanges:false
    
};

export const FolderReducer = createReducer(
    initialState,
    //  TOGGLE FOLDER CREATION
    on(FoldersActions.toggleUpsertFolder, (state,{upsertFolderProps}) => ({ 
        ...state, 
        upsertFolder: upsertFolderProps
    })),

    //  CREATE DRAFT FOLDER SUCCESS
    on(FoldersActions.createDraftFolderSuccess, (state, { folder }) => ({
        ...state,
        //draftFolders: [...state.draftFolders, folder],
        upsertFolder:{
            modalType:null,
            oldFolderId:null,
            oldFolderName:null,
            targetParentId:null,
            canShowUpsert:false,
            completed:null
        },
        result:folder,
        loading: false,
        error: null,
        success:"Folder created successfully!"
      })),

    //  CREATE COMPLETED FOLDER SUCCESS
    on(FoldersActions.createCompletedFolderSuccess, (state, { folder }) => ({
        ...state,
        //completedFolders: [...state.completedFolders, folder],
        upsertFolder:{
            modalType:null,
            oldFolderId:null,
            oldFolderName:null,
            targetParentId:null,
            canShowUpsert:false,
            completed:null
        },
        result:folder,
        loading: false,
        error: null,
        success:"Folder created successfully!"
      })),
    //  CREATE FOLDER failed
    on(FoldersActions.createFolderFailed, (state, { error }) => ({
        ...state,
        loading: false,
        error: error,
    })),

    on(FoldersActions.fetchDraftSuccess, (state, { foldersList }) => ({
        ...state,
        draftFolders:[...foldersList],
        loadingDraft:false
    })),
    on(FoldersActions.fetchCompletedSuccess, (state, { foldersList }) => ({
        ...state,
        completedFolders:[...foldersList],
        loadingCompleted:false
    })),
    on(FoldersActions.fetchArchivedSuccess, (state, { foldersList }) => ({
        ...state,
        archivedFolders:[...foldersList],
        loadingArchived:false
    })),
    on(FoldersActions.setFolderLoading, (state, { key, loading }) => ({
        ...state,
        [key]:loading
    })),
    on(FoldersActions.fetchFoldersFailed, (state, { error }) => ({
        ...state,
        error
    })),

    on(FoldersActions.trackLastNode, (state, { lastSelectedNode }) => ({
        ...state,
        lastSelectedNode:lastSelectedNode.id,
        rootOfLastSelectedNode:lastSelectedNode.root,
    })),
    
    on(FoldersActions.toggleTrackChanges, (state) => ({
        ...state,
        trackChanges: !state.trackChanges
    })),
    on(FoldersActions.upsertFolderSuccess, (state, { folder,success }) => ({
        ...state,
        completedFolders: [...state.completedFolders, folder],
        upsertFolder:{
            modalType:null,
            oldFolderId:null,
            oldFolderName:null,
            targetParentId:null,
            canShowUpsert:false,
            completed:null
        },
        result:folder,
        loading: false,
        error: null,
        success,
    })),
    on(FoldersActions.toggleArchiveModal, (state, { archive }) => ({
        ...state,
        archiveModal:{
            canShowArchiveModal:archive.canShowArchiveModal,
            archiveOperation:archive.archiveOperation,
            folderName:archive.folderName,
            targetId:archive.targetId,
            targetType:archive.targetType,
            source:archive.source
        },
        result:null,
        loading: false,
        error: null,
        success:null,
    })),
    on(FoldersActions.archiveFolderSuccess, (state, { folder,success }) => ({
        ...state,
        archiveModal:{
            canShowArchiveModal:false,
            archiveOperation:'',
            folderName:'',
            folderId:null,
            targetType:null,
            source:state.archiveModal.source
        },
        draftFolders:state.draftFolders.filter(x=>x.id!=folder),
        completedFolders:state.completedFolders.filter(x=>x.id!=folder),
        result:folder,
        loading: false,
        error: null,
        success,
    })),
    on(FoldersActions.archiveProjectSuccess, (state, { project }) => ({
        ...state,
        archiveModal:{
            canShowArchiveModal:false,
            archiveOperation:'',
            folderName:'',
            folderId:null,
            targetType:null,
            source:state.archiveModal.source
        },
        result:{},
        loading: false,
        error: null,
        success:"Project archived successfully",
    })),
    on(FoldersActions.restoreFolderSuccess, (state, { folder,success }) => ({
        ...state,
        archiveModal:{
            canShowArchiveModal:false,
            archiveOperation:'',
            folderName:'',
            folderId:null,
            targetType:null,
            source:state.archiveModal.source
        },
        archivedFolders:state.archivedFolders.filter(x=>x.id!=folder),
        result:folder,
        loading: false,
        error: null,
        success,
    })),
    on(FoldersActions.restoreProjectSuccess, (state, { project }) => ({
        ...state,
        archiveModal:{
            canShowArchiveModal:false,
            archiveOperation:'',
            folderName:'',
            folderId:null,
            targetType:null,
            source:""
        },
        result:{},
        loading: false,
        error: null,
        success:"Project restored successfully",
    })),
    on(FoldersActions.restoreProjectFailed, (state, { error }) => ({
        ...state,
        archiveModal:{canShowArchiveModal:false,archiveOperation:'',folderName:'',folderId:null,targetType:null,source:""},
        result:{},
        loading: false,
        error: true,
        success:null,
    })),
    on(FoldersActions.archiveProjectFailed, (state, { error }) => ({
        ...state,
        archiveModal:{canShowArchiveModal:false,archiveOperation:'',folderName:'',folderId:null,targetType:null,source:""},
        result:{},
        loading: false,
        error: true,
        success:null,
    })),
    on(FoldersActions.spliceFolder, (state, { id, source, destination }) => {
        const sourceArray = state[source];        
        const folderToMove = sourceArray.find(folder => folder.id === id);
    
        if (folderToMove) {
          return {
            ...state,
            [source]: sourceArray.filter(folder => folder.id !== id),
            [destination]: [...state[destination], folderToMove]  
          };
        }
        return state;
    }),
    on(FoldersActions.restoreSplicedFolder, (state, { id, source }) => {
        const sourceArray = state[source];        
        const folderToMove = sourceArray.find(folder => folder.id === id);
     
        if (folderToMove) {
            if(folderToMove.isCompleted==true){
                return {
                  ...state,
                  archivedFolders: sourceArray.filter(folder => folder.id !== id),
                  completedFolders: [...state.completedFolders,folderToMove],
                };
            }else{
                return {
                    ...state,
                  archivedFolders: sourceArray.filter(folder => folder.id !== id),
                    draftFolders: [...state.draftFolders,folderToMove],
                  };
            }
        }
        return state;
    }),
    on(FoldersActions.replaceFolder, (state, { operation }) => {
       if(operation=='create'){
            if(state.result.isCompleted==true){
                return {
                    ...state,
                    completedFolders: [
                        ...state.completedFolders,
                        {
                            id: state.result.id,
                            folderName: state.result.folderName,
                            folderLoadingCount: state.result.folderLoadingCount,
                            parentId: state.result.parentId
                        }
                    ]
                }
            }else{
                return {
                    ...state,
                    draftFolders: [
                        ...state.draftFolders,
                        {
                            id: state.result.id,
                            folderName: state.result.folderName,
                            folderLoadingCount: state.result.folderLoadingCount,
                            parentId: state.result.parentId
                        }
                    ]
                }
            }
       }
       if(operation=='edit'){
            if(state.result.isCompleted==true){
                return {
                    ...state,
                    completedFolders:state.completedFolders.map(folder =>
                        folder.id === state.result.id ? { ...folder, folderName: state.result.folderName } : folder
                    )
                }
            }else{
                return {
                    ...state,
                    draftFolders:state.draftFolders.map(folder =>
                        folder.id === state.result.id ? { ...folder, folderName: state.result.folderName } : folder
                    )
                }
            }
        }
     
        
        return state;
    }),
    on(FoldersActions.dismissResult, (state) => ({
        ...state,
        result: null
    })),
);