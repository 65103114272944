import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MaterialModalComponent } from '../components/material-modal/material-modal.component';

@Component({
  selector: 'app-custom-scenario',
  templateUrl: './custom-scenario.component.html',
  styleUrls: ['./custom-scenario.component.scss']
})
export class CustomScenarioComponent implements OnInit {
  sessionName: string = ''; // Store session name
  options: any[] = [
    {
      type: 'Select Lubricating oil',
      weight: '',
      unit: 't',
      isEditable: false
    }
  ];

  constructor(private route: ActivatedRoute, private modalService: NgbModal) {}

  ngOnInit(): void {
    // Retrieve session name from query parameters
    this.route.queryParams.subscribe((params) => {
      this.sessionName = params['sessionName'] || 'Default Session Name';
    });
  }

  openMaterialModal(): void {
    const modalRef = this.modalService.open(MaterialModalComponent, { size: 'lg' });
    modalRef.componentInstance.title = 'Fuel Option';
    modalRef.componentInstance.material = {
      name: 'Diesel Fuel',
      weight: 10,
      unit: 't'
    };
    modalRef.componentInstance.sharedComponents = [
      {
        name: 'Shared Component 1',
        company: { companyName: 'Company A' },
        note: 'Note 1',
        weight: 5,
        emission: 20.5
      },
      {
        name: 'Shared Component 2',
        company: { companyName: 'Company B' },
        note: 'Note 2',
        weight: 15,
        emission: 50.3
      }
    ];

    modalRef.result
      .then((result) => {
        if (result) {
          this.options.push({
            type: result.name || 'Custom Fuel Option',
            weight: result.weight || '',
            unit: result.unit || 't',
            isEditable: true
          });
        }
      })
      .catch(() => {
        console.log('Material modal dismissed');
      });
  }
}
