import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ProjectsState } from '../../store/reducers/project.reducer';
import { Store } from '@ngrx/store';
import { selectLoadingResultProject, selectResultProjectt, selectSelectedProjectt } from '../../store/selectors/project.selectors';
import { ProjectsActions } from '../../store/actions/project.actions';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit,OnChanges {
  @Input() selectedProject:any=null; 
  currentProject$:Observable<any>;
  loadingResultProject$:Observable<any>;

  constructor(
    private projectsStore: Store<{projects:ProjectsState}>
  ) { 
    this.currentProject$=this.projectsStore.select(selectSelectedProjectt);
    this.currentProject$=this.projectsStore.select(selectResultProjectt);
    this.loadingResultProject$=this.projectsStore.select(selectLoadingResultProject);
  }
  
  ngOnInit(): void {
  }
  ngOnChanges(): void {
    
  }

}
