import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ServiceProviderSpaceRoutingModule } from './service-provider-space-routing.module';
import { QuantifyModule } from './quantify/quantify.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ServiceProviderSpaceRoutingModule,
    QuantifyModule
  ]
})
export class ServiceProviderSpaceModule { }
