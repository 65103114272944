import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-emission-navigator',
  templateUrl: './emission-navigator.component.html',
  styleUrls: ['./emission-navigator.component.scss']
})
export class EmissionNavigatorComponent implements OnInit {
  cardTitle: string = '';
  isModalOpen: boolean = false; 
  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
  }


  navigateToVesselLifecycleScreening(): void {
   
    this.router.navigate(['../vessel-lifecycle-screening'], { relativeTo: this.route });
  }


 

  openModal(title: string): void {
    this.cardTitle = title; // Set the card title
    this.isModalOpen = true;
  }

  closeModal(): void {
    this.isModalOpen = false;
  }

  handleConfirm(sessionName: string): void {
    const fullSessionName = `${this.cardTitle} - ${sessionName}`; // Combine card title and session name
    console.log('Full Session Name:', fullSessionName);
    this.isModalOpen = false;
  
    // Navigate to the next component with the session name
    this.router.navigate(['/danish-shipping/custom-scenario'], { queryParams: { sessionName: fullSessionName } });
  }
  
}
