import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FoldersState } from '../../../store/reducers/folder.reducer';
import { FoldersActions } from '../../../store/actions/folder.actions';

@Component({
  selector: 'app-archived-details',
  templateUrl: './archived-details.component.html',
  styleUrls: ['./archived-details.component.scss']
})
export class ArchivedDetailsComponent implements OnInit {
  @Input() currentProject:any=null;
  
  public projectInfo=[
    {stateValue:false,state:"screening-setup",name:"Project Setup"},
    {stateValue:false,state:"system-definition",name:"System Definition"},
    {stateValue:false,state:"screening-inventory",name:"Product Inventory"},
    {stateValue:false,state:"review",name:"Project Review"},
  ];
  constructor(
    private foldersStore: Store<{ folders: FoldersState }>,
  ) { }

  ngOnInit(): void {
    console.log(this.currentProject);
    
  }

  restore(){
    this.foldersStore.dispatch(FoldersActions.toggleArchiveModal(
      {
        archive:{
          canShowArchiveModal:true,
          archiveOperation:'restore',
          folderName:this.currentProject.name,
          targetId:this.currentProject.id,
          targetType:'project',
          source:""
        }
      }
    ))
  }

}
