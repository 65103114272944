<div class="folder-item" >
    <img src="../../../assets/archived-closed.png" alt="" *ngIf="folderName=='Archived'">
    <img src="../../../assets/fm_closed_folder@2x.png" alt="" *ngIf="folderName!='Archived'">
    <div class="folder-label-container">
      <label for="" class="folder-name">{{folderName}}</label>
        <div class="operations-container" >
            <div class="spinner-border text-secondary" style="width: 25px; height: 25px; border-color: #123B68;border-right-color: transparent;">
            </div>
        </div>
    </div>
    <img *ngIf="false" class="folder-loading" src="../../../assets/Loading-icon-animation.svg" alt="" />
</div>