<div class="modals" >
    <div class="main-modal-content" >
        <ng-container *ngIf="loading==false">
            <div class="header">
                <em class="icon-close" (click)="close()"></em>
            </div>
            <div class="body">
                <img src="../../../../assets/move_filled.png" alt="">
                <label for="" class="title">MOVE PROJECT</label>
                <p>Are you sure you want to move this project to the selected folder?</p>
                <div class="actions-container">
                    <button class="cancel" (click)="close()">Cancel</button>
                    <button class="move rename-btn move-btn" (click)="moveProject()">Move</button>
                </div>
            </div>
        </ng-container>
        <div class="loading-indicator" *ngIf="loading==true">
            <div class="spinner-border text-secondary"></div>
            <label for="">Loading project details..</label>
        </div>
    </div>
    
</div>
<div class="overlay"></div>
