import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DanishShippingComponent } from './danish-shipping.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ResultsOverviewComponent } from './results-overview/results-overview.component';
import { EmissionNavigatorComponent } from './emission-navigator/emission-navigator.component';
import { VesselLifecycleScreeningComponent } from './vessel-lifecycle-screening/vessel-lifecycle-screening.component';
import { VesselLifecycleScreeningResultComponent } from './components/vessel-lifecycle-screening-result/vessel-lifecycle-screening-result.component';
import { CustomScenarioComponent } from './custom-scenario/custom-scenario.component';


const routes: Routes = [
  {
    path: '',
    component: DanishShippingComponent,
    children:[
      {
        path: '',
        component: DashboardComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'results-overview',
        component: ResultsOverviewComponent
      },
      { 
        path: 'emission-navigator',
        component: EmissionNavigatorComponent 
      },
      { 
        path: 'vessel-lifecycle-screening',
        component: VesselLifecycleScreeningComponent 
      },
      { 
        path: 'vessel-lifecycle-screening-result',
        component: VesselLifecycleScreeningResultComponent 
      },
      {
        path: 'custom-scenario', // Add the route
        component: CustomScenarioComponent
      }

    ]
  },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DanishShippingRoutingModule { }
