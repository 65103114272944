
<div class="panel-container" (scroll)="onScroll($event)">
    <ng-container *ngFor="let project of projects$ | async">
        <div class="project-item" (click)="selectProject(project)" [ngClass]="{'selected-project':selectedProject==project}">
            <img src="../../../assets/pi_draft_projects.svg" alt="">
            <label for="" class="project-name">{{project.name}}</label>
        </div>    
    </ng-container>
    
    <ng-container *ngIf="(isLoading$|async) as isLoading">
        <div class="loading-indicator" *ngIf="isLoading==true">
            <div class="spinner-border text-secondary"></div>
            <label for="">Loading more projects..</label>
        </div>
    </ng-container>
</div>