import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../services/yardscore-services/users.service';
import { AuthService } from '@auth0/auth0-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { delay, distinctUntilChanged, shareReplay, tap } from 'rxjs/operators';
import { BehaviorSubject, Subscription, throwError } from 'rxjs';

@Component({
    selector: 'app-redirection',
    templateUrl: './redirection.component.html',
    styleUrls: ['./redirection.component.scss']
})
export class RedirectionComponent implements OnInit {
    public currentUser: any;
    public token: any;
    public isLoading: boolean = true;
    private subscriptions: Subscription = new Subscription();
  
    constructor(
      private userService: UserService,
      private auth: AuthService,
      private router: Router,
      private route: ActivatedRoute
    ) {}
  
    ngOnInit(): void {
      this.subscriptions.add(
        this.route.fragment.subscribe({
          next: (fragment) => {
            if (fragment) {
              const fragmentObject = this.parseFragment(fragment);
              if (fragmentObject['error']) {
                this.handleFragmentError(fragmentObject['error']);
              } else {
                this.doRedirection();
              }
            } else {
              this.doRedirection();
            }
          },
          error: (err) => this.handleError(err, 'Invalid login link.')
        })
      );
    }
  
    public doRedirection(): void {
      this.subscriptions.add(
        this.auth.isAuthenticated$.subscribe({
          next: (isAuthenticated) => {
            if (isAuthenticated) {
              this.getAccessTokenAndUser();
            } else {
              this.handleUnauthenticated();
            }
          },
          error: (err) => this.handleError(err, 'Cannot obtain authentication status.')
        })
      );
    }
  
    private getAccessTokenAndUser(): void {
      this.subscriptions.add(
        this.auth.getAccessTokenSilently().subscribe({
          next: (token) => {
            this.token = token;
            localStorage.setItem('access_token', token);
            this.isLoading = false;
            this.fetchCurrentUser();
          },
          error: (err) => this.handleError(err, 'Error while fetching access privileges.')
        })
      );
    }
  
    private fetchCurrentUser(): void {
      this.subscriptions.add(
        this.userService.getCurrentUser().subscribe({
          next: (user) => {
            this.currentUser = user;
            this.navigateFromLogin();
          },
          error: (err) => this.handleError(err, 'Error while fetching current user.')
        })
      );
    }
  
    private handleUnauthenticated(): void {
      this.subscriptions.add(
        this.auth.getAccessTokenSilently().subscribe({
          next: () => {
            this.isLoading = false;
          },
          error: (error) => {
            this.isLoading = false;
            if (error.error === 'login_required' || error.error === 'consent_required') {
              this.auth.loginWithRedirect({
                authorizationParams: {
                  connection: 'email',
                  send: 'link'
                },
                appState: { target: '/redirection' }
              });
            } else {
              this.handleError(error, 'User unauthenticated.');
            }
          }
        })
      );
    }
  
    private navigateFromLogin(): void {
      localStorage.setItem('companyId', this.currentUser.companyId);
      localStorage.removeItem('isRedirecting');
  
      if (!this.currentUser.redirectTo) {
        
        //this.auth.logout({ returnTo: `${window.location.origin}/error` });
        this.handleError(null, 'Error while redirection. Cannot obtain user data.')
      } else {
        this.router.navigate([this.currentUser.redirectTo]);
      }
    }
  
    private handleFragmentError(error: string): void {
      if (!localStorage.getItem('access_token')) {
        this.handleError(null, 'Login link expired or broken.')
      } else {
        this.router.navigate(['/redirection']);
      }
    }
  
    private parseFragment(fragment: string): any {
      const fragmentString = fragment.startsWith('#') ? fragment.substring(1) : fragment;
      const pairs = fragmentString.split('&');
      const fragmentObject: any = {};
      pairs.forEach((pair) => {
        const [key, value] = pair.split('=');
        fragmentObject[decodeURIComponent(key)] = decodeURIComponent(value || '');
      });
      return fragmentObject;
    }
  
    private handleError(error: any, message: string): void {
        sessionStorage.clear();
        localStorage.clear();
        localStorage.setItem('error',message);
        localStorage.setItem('error--v',error)
        this.auth.logout({ returnTo: `${window.location.origin}/error` });
    }
  
    ngOnDestroy(): void {
      this.subscriptions.unsubscribe();
    }
}
