<div class="project-details" >
    <div class="header-p">
        <div class="project-det-actions">
            <img src="../../../assets/books.svg" style="width:28px !important ;margin-left: 5px" alt="" placement="top" ngbTooltip="Archive" (click)="archivePorject()">
            <img src="../../../assets/move.png" style="width: 30px !important;margin-top: 2px;" alt="" placement="top" ngbTooltip="Move to folder" (click)="moveProject()">
        </div>
        <img src="../../../assets/open.svg" alt="" style="float:right;" class="open" [routerLink]="['./screening',currentProject.id]">

    </div>
    <div class="body">
        <label for="" class="title">
            {{currentProject?.name}}
        </label>

        <img src="../../../assets/pi_draft_projects.svg" alt="" class="logo">
        <label for="" class="info">
            Information
        </label>
    </div>
    <ng-container *ngFor="let item of projectInfo">
        <div class="info-item">
            <label for="" class="label">{{item.name}}</label>
            <div class="status-container" *ngIf="item.stateValue==true">
                <img src="../../../assets/completed_stage@2x.png" alt="">
                <label for="" class="status stat-complete">Completed</label>
            </div>
            <div class="status-container" *ngIf="item.stateValue==false">
                <img src="../../../assets/in_progress@2x.png" alt="">
                <label for="" class="status stat-progress">In progress</label>
            </div>
        </div>
    </ng-container>

    <div class="info-item">
        <label for="" class="label">Establish date:</label>
        <div class="status-container">
            <label for="" class="status stat-date">{{currentProject?.creationDate|date:'MMM d, y, h:mm:ssa'}}</label>
        </div>
    </div>
</div>