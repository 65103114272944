<div id="myModal" class="modal">
    <div class="modal-content">
        <div class="header-modal">
            <em class="icon-close" style="color: #ffffff;right: 10px;top: 10px;" (click)="close()"></em>
        </div>
        <ng-container *ngIf="loadingShare==false">
    
            <label class="share-title">Do you want to share this assessment?</label>
            <label class="share-description">Please copy this code and share it with companies of your choosing</label>
            <div class="screenings link spacing">
                <label class="screening-name">{{currentProject.reflowId}}</label>
                <em class="fa fa-copy screening-copy" [cdkCopyToClipboard]="currentProject.reflowId" (click)="share()"></em>
            </div>
            <label class="left-description">*The company will only see your result</label>
        </ng-container>
        <div class="loading-indicator" *ngIf="loadingShare==true">
            <div class="spinner-border text-secondary"></div>
            <label for="">Sharing project..</label>
        </div>
    </div>
</div>