import { Component, OnInit } from '@angular/core';
import { MaterialModalComponent } from '../components/material-modal/material-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-vessel-lifecycle-screening',
  templateUrl: './vessel-lifecycle-screening.component.html',
  styleUrls: ['./vessel-lifecycle-screening.component.scss']
})
export class VesselLifecycleScreeningComponent implements OnInit {


  activeTabIndex: number = 0; // Index of the active tab

    // Array to store rows
    options: any[] = [
      {
        type: 'Select Lubricating oil',
        weight: '',
        unit: 't',
        isEditable: false
      },
    
    ];

    constructor(private modalService: NgbModal) {}


  ngOnInit(): void {
  }


    // Method to set the active tab
    setActiveTab(index: number): void {
      this.activeTabIndex = index;
    }
  
    // Method to go to the next tab
    goToNextTab(): void {
      if (this.activeTabIndex < 2) { // Assuming there are 3 tabs (0, 1, 2)
        this.activeTabIndex++;
      }
    }

    addOption(): void {
      this.options.push({
        type: 'Select Lubricating oil',
        weight: '',
        unit: 't',
        isEditable: true
      });
    }


    openMaterialModal(): void {
      const modalRef = this.modalService.open(MaterialModalComponent, { size: 'lg' });
      modalRef.componentInstance.title = 'Fuel Option'; // Set title
      modalRef.componentInstance.material = { // Pre-fill the modal with fake data
        name: 'Diesel Fuel',
        weight: 10,
        unit: 't'
      };
      modalRef.componentInstance.sharedComponents = [ // Add fake shared components
        {
          name: 'Shared Component 1',
          company: { companyName: 'Company A' },
          note: 'Note 1',
          weight: 5,
          emission: 20.5
        },
        {
          name: 'Shared Component 2',
          company: { companyName: 'Company B' },
          note: 'Note 2',
          weight: 15,
          emission: 50.3
        }
      ];
    
      modalRef.result
        .then((result) => {
          if (result) {
            this.options.push({
              type: result.name || 'Custom Fuel Option',
              weight: result.weight || '',
              unit: result.unit || 't',
              isEditable: true
            });
          }
        })
        .catch((dismissed) => {
          console.log('Modal dismissed');
        });
    }
    

}
