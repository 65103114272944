import { Component } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from "src/environments/environment";
import { AutoLogoutService } from "./services/auto-logout.service";
import { PromptUpdateService } from "./services/prompt-update.service";
import { Store } from "@ngrx/store";



@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "reflowUI";
  environmentUrl = "Debug api";

  constructor( 
    private router: Router,
    private activatedRoute:ActivatedRoute, 
    private promptUpdateService:PromptUpdateService,
    
  ) {
    this.environmentUrl = environment.apiUrl;
    let ua = navigator.userAgent;
  if (
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    if(window.location.href.includes("public-assessment")==false){
      this.router.navigateByUrl("mobile");
    }
  }
  }
  interactionInProgress = false;
  ngOnInit() {
    localStorage.setItem("isRedirecting","false");

  }


}
