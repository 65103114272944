import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty-folder-node',
  templateUrl: './empty-folder-node.component.html',
  styleUrls: ['./empty-folder-node.component.scss']
})
export class EmptyFolderNodeComponent implements OnInit {
  @Input() folderName:any="";
  constructor() { }

  ngOnInit(): void {
  }

}
