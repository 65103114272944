<div class="modals" >
    <div class="main-modal-content upsert" *ngIf="(modalType$|async)=='create-folder'">
        <div class="header">
            <em class="icon-close" (click)="toggleUpsertFolder(null)"></em>
            <div class="title-container">
                <img src="../../../../assets/new_folder@2x.png" alt="">
                <label for="" class="title">Create new folder</label>
            </div>
            <div class="form-container">
                <div class="form-group">
                    <label class="input-title">Enter folder name:</label>
                    <input type="text" class="form-control-c" placeholder="Folder name" [(ngModel)]="folderName">
                </div>
            </div>
            <div class="form-container">
                <div class="form-group">
                    <label class="input-title">Place the folder in:</label>
                    <div class="input-container" (click)="folderType='Completed'">
                        <div class="form-check-container">
                            <div class="form-control-check">
                                <img src="../../../../assets/check@2x.png" alt="" *ngIf="folderType=='Completed'">
                            </div>
                        </div>
                        <div class="input-label">
                            <img src="../../../../assets/create_new_folder.png" alt="">
                            <label for="" class="title">Completed</label>
                        </div>
                    </div>
                    <div class="input-container mt-2"  (click)="folderType='Draft'">
                        <div class="form-check-container">
                            <div class="form-control-check">
                                <img src="../../../../assets/check@2x.png" alt="" *ngIf="folderType=='Draft'">
                            </div>
                        </div>
                        <div class="input-label">
                            <img src="../../../../assets/create_new_folder.png" alt="">
                            <label for="" class="title">Draft</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer">
                <button class="cancel" (click)="toggleUpsertFolder(null)">Cancel</button>
                <button class="create" (click)="createFolder()">Create</button>
            </div>
        </div>
    </div>
    <div class="main-modal-content rename"  *ngIf="(modalType$|async)=='edit-folder'">
        <div class="header">
            <em class="icon-close"  (click)="toggleUpsertFolder(null)"></em>
        </div>
        <div class="body">
            <div class="title-container">
                <img src="../../../../assets/create_new_folder.png" alt="">
                <label for="" class="title">Rename folder</label>
            </div>
            <div class="form-group">
                <label class="input-title">Enter new folder name:</label>
                <input type="text" class="form-control" placeholder="Folder name" [(ngModel)]="folderName">
            </div>
            <div class="actions-container">
                <button class="cancel" (click)="toggleUpsertFolder(null)">Cancel</button>
                <button class="rename rename-btn" (click)="renameFolder()">Rename</button>
            </div>
        </div>
    </div>
    <div class="main-modal-content rename" *ngIf="(modalType$|async)=='create-sub-folder'">
        <div class="header">
            <em class="icon-close" (click)="toggleUpsertFolder(null)"></em>
        </div>
        <div class="body" >
            <div class="title-container">
                <img src="../../../../assets/new_folder@2x.png" alt="">
                <label for="" class="title">Create Sub-folder</label>
            </div>
            <div class="form-group">
                <label class="input-title">Enter new sub-folder name:</label>
                <input type="text" class="form-control" placeholder="Sub-folder name" [(ngModel)]="folderName">
            </div>
            <div class="actions-container">
                <button class="cancel" (click)="toggleUpsertFolder(null)">Cancel</button>
                <button class="rename rename-btn" (click)="createSubFolder()">Create</button>
            </div>
        </div>
        <div class="body" *ngIf="false">
            <div class="title-container">
                <img src="../../../../assets/reflower_loading.gif" style="width: 363px;">
            </div>
        </div>
    </div>
</div>
<div class="overlay"></div>
