import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProjectsState } from '../../store/reducers/project.reducer';
import { ToastrService } from 'ngx-toastr';
import { ProjectsActions } from '../../store/actions/project.actions';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { selecterror, selectImportedProject } from '../../store/selectors/project.selectors';

@Component({
  selector: 'app-import-project-modal',
  templateUrl: './import-project-modal.component.html',
  styleUrls: ['./import-project-modal.component.scss']
})
export class ImportProjectModalComponent implements OnInit {

  @Input() companyId:any=null;

  importLink:any="";
  loading:boolean=false;

  error$:Observable<any>;
  importedProject$:Observable<any>;
  
  constructor(
    private projectsStore: Store<{ projects: ProjectsState }>,
    private toasterService: ToastrService,
  ) { 
    this.error$=projectsStore.select(selecterror)
    this.importedProject$=projectsStore.select(selectImportedProject)
  }

  ngOnInit(): void {
  }

  hideImportModal(){
    this.projectsStore.dispatch(ProjectsActions.toggleImportModal({canShow:false}));
  }
  
  importScreening(){
    this.loading=true;
    this.projectsStore.dispatch(ProjectsActions.importProject({project:{
      companyId:this.companyId,
      reflowId:this.importLink
    }}));
    this.checkResult();
  }

  checkResult(){
    this.error$.pipe(
      filter(error => !!error), 
      take(1)
    ).subscribe(error => {
      this.loading=false;
      this.toasterService.error('Operation failed');
      this.hideImportModal()
    });
    this.importedProject$.pipe(
      filter(importedProject => !!importedProject), 
      take(1)
    ).subscribe(ans => {
      this.loading=false;
      this.toasterService.success('Project Imported successfully');
      this.hideImportModal()
    });
  }

}
