import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { VesselsOverviewState } from '../../store/reducers/vessels-overview.reducers';
import { Store } from '@ngrx/store';
import { VesselsOverviewActions } from '../../store/actions/vessels-overview.actions';

@Component({
  selector: 'app-vessels-header',
  templateUrl: './vessels-header.component.html',
  styleUrls: ['./vessels-header.component.scss']
})
export class VesselsHeaderComponent implements OnInit {
  
  constructor(
    private vesselsOverviewStore: Store<{ vesselsOverview: VesselsOverviewState }>,
  ) { }

  ngOnInit(): void {
  }

  onAddVesselClick() {
    this.vesselsOverviewStore.dispatch(VesselsOverviewActions.toggleVesselDrawer({canShow:true}))
  }
}
