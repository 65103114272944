<div class="modal">
    <div class="modal-content import-modal" *ngIf="(data$ | async) as data ">
        <ng-container *ngIf="loading==false">
            <div class="modal-header" style="margin-top: -1px;
            width: 515px;
            margin-left: -1px;">
                <em class="icon-close close-import" (click)="toggleUpsertFolder()" style="margin-left: 460px;"></em>
            </div>
            <div class="image-container">
                <img src="../../../../../assets/archive-solid.png" class="archive-image" style="margin-top: 25px;"
                    *ngIf="data.archiveOperation=='restore'">
                <img src="../../../../../assets/archive-solid1.png" class="archive-image" style="margin-top: 10px;"
                *ngIf="data.archiveOperation=='archive'">
            </div>
            <label class="share-title import-title archive-title" *ngIf="data.archiveOperation=='archive'">ARCHIVING</label>
            <label class="share-title import-title archive-title" *ngIf="data.archiveOperation=='restore'">RESTORING</label>
            <p class="sub-heading-text">Are you sure you want to {{data.archiveOperation}} this {{data.targetType}}?</p>
            <label class="share-description import-description" *ngIf="data.archiveOperation=='archive'" style="margin-top: -12px; margin-bottom: 25px;">
                The content will be transferred to the archived folder. You can always restore it later.
            </label>
            <label class="share-description import-description" *ngIf="data.archiveOperation=='restore'" style="margin-top: -12px; margin-bottom: 25px;">
                The content will be restored to the original folder.
            </label>
            <div class="flex-container">
                <div class="btn-div"><button class="big-blue-outline  cancel no-margin"
                        (click)="toggleUpsertFolder()">Cancel</button></div>
                <div class="btn-div"><button class="big-blue-outline import-btn no-margin" 
                        (click)="archiveFolder(data.targetId,data.archiveOperation)">{{data.archiveOperation=='archive'?'Archive':'Restore'}}</button></div>
            </div>
        </ng-container>
        <div class="operations-container" *ngIf="loading==true">
            <div class="spinner-border text-secondary">
            </div>
            <label for="">Loading...</label>
        </div>
    </div>

</div>