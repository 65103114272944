import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ProjectsActions } from '../actions/project.actions';
import { select, Store } from '@ngrx/store';
import { QuantifyService } from '../../../../services/quantify.service';
import { ProjectsState } from '../reducers/project.reducer';
import { ScreeningService } from '../../../../services/screening.service';
import { UserState } from '../../../../store/reducers/user.reducers';
import { selectEmptyProject } from '../selectors/project.selectors';
import { selectCurrentUser, selectUserInfo } from '../../../../store/selectors/user.selectors';
import { Router } from '@angular/router';
import { CompanyFoldersService } from '../../../../services/company-folders.service';
import { ValidationService } from '../../../../services/validation.service';

@Injectable()
export class ProjectEffects {
  constructor(
    private actions$: Actions,
    private quantifyService: QuantifyService,
    private screeningService: ScreeningService,
    private validationService: ValidationService,
    private companyFoldersService: CompanyFoldersService,
    private store: Store<{ projects: ProjectsState }>,
    private userStore: Store<{ user: UserState }>,
    private router: Router,

  ) {}
  loadRecentProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.loadRecentProjects),
      tap(() => {
        this.store.dispatch(ProjectsActions.setLoading({ loadingRecent: true }));
      }),
      mergeMap(action => {
        return this.quantifyService.getRecentprojects(action.companyId).pipe(
            map(recentProjects => {
                return ProjectsActions.loadRecentProjectsSuccess({ recentProjects });
            }),
          catchError(error => of(ProjectsActions.loadRecentProjectsFailed({ error })))
        );
      })
    )
  );

  createEmptyProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.createNewProject),
      mergeMap(action => {
        return this.screeningService.createEmptyScreening(action.emptyScreeningDto).pipe(
            map(newEmptyProject => {
                this.router.navigate([
                  "/homeServiceProvider",
                  action.emptyScreeningDto.companyId,
                  "quantify",
                  "screening",
                  newEmptyProject['id']
                ])
                return ProjectsActions.createNewProjectSuccess({ newEmptyProject });
            }),
          catchError(error => of(ProjectsActions.createNewProjectFailed({ error })))
        );
      })
    )
  );

  createScreeningLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.createNewProjectSuccess), 
      withLatestFrom(
        this.userStore.pipe(select(selectCurrentUser)),
        this.userStore.pipe(select(selectUserInfo)),
        this.userStore.pipe(select(selectEmptyProject))
      ), 
      switchMap(([action, currentUser,userInfo,newEmptyProject]) => {

        let screeningLog = { 
          date: new Date(), 
          description: "Initializing the screening", 
          screeningId: newEmptyProject.id, 
          userName: userInfo.fullName 
        };
        return this.screeningService.createScreeningLog(screeningLog).pipe(
          map(ans => {
            //console.log(ans),
            return ProjectsActions.loadRecentProjects({ companyId:currentUser.companyId })
          }),
          catchError(error => of(ProjectsActions.emptyAction()))
        );
      })
    )
  );

  searchProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.searchProject),
      tap(() => {
        this.store.dispatch(ProjectsActions.loadingSearch({ loadingSearch: true }));
      }),
      mergeMap(action => {
        return this.quantifyService.searchProject(action.search.companyId,action.search.screeningName).pipe(
            map(searchResult => {
                this.store.dispatch(ProjectsActions.loadingSearch({ loadingSearch: false }));
                return ProjectsActions.searchProjectSuccess({ searchResult });
            }),
            catchError(({ error }) => {
              this.store.dispatch(ProjectsActions.loadingSearch({ loadingSearch: false }));
              return of(ProjectsActions.searchProjectFailed());
            })
        );
      })
    )
  );

  loadMoreProjectsForRootNode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.loadMore),
      tap(() => {
        this.store.dispatch(ProjectsActions.toggleLoadingMore());
      }),
      mergeMap(action => {
        return this.quantifyService.getScreeningByStatus(action.getScreeningsByStatusDto).pipe(
            map(projects => {
                this.store.dispatch(ProjectsActions.toggleLoadingMore());
                return ProjectsActions.loadMoreSuccess({ projects });
            }),
            catchError(({ error }) => {
              return of(ProjectsActions.loadMoreFailed({ error }));
            })
        );
      })
    )
  );
  loadMoreOfSubfolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.loadMoreOfSubfolder),
      tap(() => {
        this.store.dispatch(ProjectsActions.toggleLoadingMore());
      }),
      mergeMap(action => {
        return this.quantifyService.getScreeningByFolder(action.getScreeningByFolderDto).pipe(
            map(projects => {
                this.store.dispatch(ProjectsActions.toggleLoadingMore());
                return ProjectsActions.loadMoreOfSubfolderSuccess({ projects });
            }),
            catchError(({ error }) => {
              return of(ProjectsActions.loadMoreOfSubfolderFailed({ error }));
            })
        );
      })
    )
  );

  fetchProjectDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.fetchSelectedProject),
      mergeMap(action => {
        return this.screeningService.getScreeningById(action.project.id).pipe(
            map(project => {
                return ProjectsActions.fetchSelectedProjectSuccess({ project });
            }),
            catchError(({ error }) => {
              return of(ProjectsActions.fetchSelectedProjectFailed({ error }));
            })
        );
      })
    )
  );

  moveToFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.moveToFolder),
      mergeMap(action => {
        return this.companyFoldersService.moveProjectToFolder(action.project).pipe(
            map(project => {
                return ProjectsActions.moveToFolderSuccess({ project });
            }),
            catchError(({ error }) => {
              return of(ProjectsActions.moveToFolderError({ error }));
            })
        );
      })
    )
  );

  shareProjec$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.shareProject),
      mergeMap(action => {
        return this.screeningService.activateShare(action.id).pipe(
            map(project => {
                return ProjectsActions.shareProjectSuccess({ project });
            }),
            catchError(({ error }) => {
              return of(ProjectsActions.shareProjectFailed({ error }));
            })
        );
      })
    )
  );

  duplicateProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.duplicateProject),
      mergeMap(action => {
        return this.screeningService.copyScreening(action.id).pipe(
            map(project => {
                return ProjectsActions.duplicateProjectSuccess({ project });
            }),
            catchError(({ error }) => {
              return of(ProjectsActions.duplicateProjectFailed({ error }));
            })
        );
      })
    )
  );

  getValidationRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.getValidationRequest),
      mergeMap(action => {
        return this.validationService.hasValidationRequest(action.id).pipe(
            map(request => {
                return ProjectsActions.getValidationRequestSuccess({ request });
            })
        );
      })
    )
  );

  getHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.getHistory),
      mergeMap(action => {
        return this.validationService.getHistory(action.id).pipe(
            map(history => {
                return ProjectsActions.getHistorySuccess({ history });
            })
        );
      })
    )
  );

  downloadDataSheet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.downloadExcelSheet),
      mergeMap(action => {
        return this.screeningService.getExcelResult(action.id,action.name).pipe(
            map(blob => {
                this.screeningService.DownloadFile(blob,action.name+".xlsx", 'application/pdf');
                return ProjectsActions.downloadSuccess();
            }),
            catchError(({ error }) => {
              return of(ProjectsActions.downloadFailed({ error }));
            })
        );
      })
    )
  );

  downloadReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.downloadPdf),
      mergeMap(action => {
        return this.screeningService.getProofOfAssessment(action.id,action.name).pipe(
            map(blob => {
                this.screeningService.DownloadFile(blob, action.name+".pdf", 'application/pdf');
                return ProjectsActions.downloadSuccess();
            }),
            catchError(({ error }) => {
              return of(ProjectsActions.downloadFailed({ error }));
            })
        );
      })
    )
  );
  downloadCertificate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.downloadPdf),
      mergeMap(action => {
        return this.screeningService.getProofOfAssessment(action.id,action.name).pipe(
            map(project => {
                return ProjectsActions.downloadSuccess();
            }),
            catchError(({ error }) => {
              return of(ProjectsActions.downloadFailed({ error }));
            })
        );
      })
    )
  );

  createValidationRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.createValidationRequest),
      mergeMap(action => {
        return this.validationService.requestValidation(action.addValidationRequestDto).pipe(
            map(request => {
                return ProjectsActions.createValidationRequestSuccess({request});
            }),
            catchError(({ error }) => {
              return of(ProjectsActions.createNewProjectFailed({ error }));
            })
        );
      })
    )
  );

  changeValidationContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.changeValidationContact),
      mergeMap(action => {
        return this.validationService.updateResponsible(action.updateResponsibleDto).pipe(
            map(contact => {
                return ProjectsActions.changeValidationContactSuccess({contact});
            }),
            catchError(({ error }) => {
              return of(ProjectsActions.changeValidationContacttFailed({ error }));
            })
        );
      })
    )
  );

  improtProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActions.importProject),
      mergeMap(action => {
        return this.screeningService.addSharedScreening(action.project).pipe(
            map(project => {
                return ProjectsActions.importProjectSuccess({project});
            }),
            catchError(({ error }) => {
              return of(ProjectsActions.importProjectFailed({ error }));
            })
        );
      })
    )
  );
  
}