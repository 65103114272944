<div class="move-project-container" >
    <div class="description-container">
        <img src="../../../assets/move.png" alt="">
        <label for="" class="desc-text">To move the selected project(s) - please select the desired folder or subfolder.</label>
    </div>
    <button class="move-button" (click)="showMoveProjectModal()">
        <img src="../../../assets/check.svg" alt="" >
        <label>Move to folder</label>
    </button>
    <div class="close-move" (click)="close()">
        <img src="../../../assets/clear.svg" alt="" >
    </div>
</div>