import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ProjectsState } from '../../../store/reducers/project.reducer';
import { ProjectsActions } from '../../../store/actions/project.actions';
import { FoldersState } from '../../../store/reducers/folder.reducer';
import { FoldersActions } from '../../../store/actions/folder.actions';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-draft-details',
  templateUrl: './draft-details.component.html',
  styleUrls: ['./draft-details.component.scss']
})
export class DraftDetailsComponent implements OnInit,OnChanges {
  @Input() currentProject:any=null;
  
  public projectInfo=[
    {stateValue:false,state:"screening-setup",name:"Project Setup"},
    {stateValue:false,state:"system-definition",name:"System Definition"},
    {stateValue:false,state:"screening-inventory",name:"Product Inventory"},
    {stateValue:false,state:"review",name:"Project Review"},
  ];
  constructor(
    private projectsStore: Store<{ projects: ProjectsState }>,
    private foldersStore: Store<{ folders: FoldersState }>,
  ) { }

  ngOnInit(): void {

  }

  ngOnChanges(): void {
    
  }

  
  moveProject(){
    this.projectsStore.dispatch(ProjectsActions.toggleMoveProject({canMoveProject:true}))
  }

  archivePorject(){
    this.foldersStore.dispatch(FoldersActions.toggleArchiveModal(
      {
        archive:{
          canShowArchiveModal:true,
          archiveOperation:'archive',
          folderName:this.currentProject.name,
          targetId:this.currentProject.id,
          targetType:'project',
          source:""
        }
      }
    ))
  }

}
