import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { HelperService } from "./helper.service";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyFoldersService {

  environmentUrl = "Debug api";

  
  constructor(
      private http: HttpClient,
      private helperService:HelperService,
    ) {
    this.environmentUrl = environment.apiUrl;
  }

  public createFolder(folder):Observable<any>{
    return this.http.post(
      `${this.environmentUrl}/api/folders/addFolder`,
      folder,
      this.helperService.headers()
    );
  }

  public getFoldersOfCompany(id):Observable<any>{
    return this.http.get<any>(
      `${this.environmentUrl}/api/folders/getFolders/`+id,
      this.helperService.headers()
    );
  }


  public editFolder(folder):Observable<any>{
    return this.http.put<any>(
      `${this.environmentUrl}/api/folders/editFolder/${folder.id}`,
      folder,
      this.helperService.headers()
    );
  }

  public moveProjectToFolder(data){
    return this.http.post<any>(
      `${this.environmentUrl}/api/folders/assignProject`,
      data,
      this.helperService.headers()
    );
  }

  public archiveFolder(fid){
    return this.http.get<any>(
      `${this.environmentUrl}/api/quantify/Folders/archive/${fid}`,
      this.helperService.headers()
      )
  }

  public restoreFolder(fid){
    return this.http.get<any>(
      `${this.environmentUrl}/api/quantify/Folders/restore/${fid}`,
      this.helperService.headers()
      )
  }
 public buildTree(flatData: any[],root:any,lookup:any): any[] {
  flatData.unshift({
    id: 0,
    folderName: root,
    folderLoadingCount: 0,
    parentId: null
  })
  const idMapping = flatData.reduce((acc, node) => {
    acc[node.id] = { ...node, expanded: lookup[node.id]==true?true:false, children: [] };
    return acc;
  }, {} as { [key: number]: any });
  

  const rootNodes: any[] = [];
  
  flatData.forEach(node => {
    const currentNode = idMapping[node.id];
    
    if (node.id === 0 || node.parentId === null) {
      // Ensure only the synthetic root node is the true root
      if (node.id === 0) {
        idMapping[0] = currentNode;
      }
    } else {
      const parent = idMapping[node.parentId];
      if (parent) {
        parent.children.push(currentNode);
      }
    }
  });
  return [idMapping[0]];
}
}
