import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { VesselsOverviewState } from './store/reducers/vessels-overview.reducers';
import { selectCanShowVesselDrawer } from './store/selectors/vessels-overview.selectors';

@Component({
  selector: 'app-danish-shipping',
  templateUrl: './danish-shipping.component.html',
  styleUrls: ['./danish-shipping.component.scss']
})
export class DanishShippingComponent implements OnInit {

  canShowVesselDrawer$:Observable<any>;

  constructor(
    private vesselsOverviewStore: Store<{ vesselsOverview: VesselsOverviewState }>,
  ) { 
    this.canShowVesselDrawer$=vesselsOverviewStore.select(selectCanShowVesselDrawer);
  }
  
  ngOnInit(): void {
    
  }



}
