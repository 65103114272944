import { createAction, props } from '@ngrx/store';
import { UpsertFolderProps } from '../models/upsert-folder-props';
import { Folder } from '../models/folder';


export const toggleUpsertFolder = createAction ("[New Folder] toggle upsert folder modal",props<{ upsertFolderProps: any }>());



export const createFolder = createAction("[Create new folder] create new folder", props<{ folder: Folder }>());
export const createSubFolder = createAction("[Create sub folder] create sub folder", props<{ subfolder: any }>());
export const editFolder = createAction("[Edit folder] edit sub folder", props<{ folder: any }>());
export const upsertFolderSuccess = createAction("[Edit folder] upsert folder success", props<{ folder: any, success:any }>());




export const createDraftFolderSuccess = createAction("[Create new folder] create new draft folder success", props<{ folder: any }>());
export const createCompletedFolderSuccess = createAction("[Create new folder] create new completed folder success", props<{ folder: any }>());
export const createFolderFailed = createAction("[Create new folder] create new folder failed", props<{ error: any }>());

export const toggleImportProject = createAction ("[Import Project] toggle import project modal");
export const importProject = createAction("[Import Project modal] import project");

export const toggleTrackChanges = createAction("[Upsert Modal] track change");


export const fetchFolders = createAction(
  "[Folders tree] get folders", 
  props<{ getFolderDto: any }>()
);
export const fetchDraftSuccess = createAction("[Folders tree] load draft folders success", props<{ foldersList: any }>());
export const fetchCompletedSuccess = createAction("[Folders tree] load completed folders success", props<{ foldersList: any }>());
export const fetchArchivedSuccess = createAction("[Folders tree] load archive folders success", props<{ foldersList: any }>());
export const fetchFoldersFailed = createAction("[Folders tree] load folders failed", props<{ error: any }>());

export const trackLastNode = createAction(
  "[Folders tree] track last selected node", 
  props<{ lastSelectedNode: any }>())
;



export const dismissResult = createAction(
  "[Folders State] dismiss result")
;


export const setLoading = createAction(
    '[Folder] Set Loading',
    props<{ loading: boolean }>()
);

export const setFolderLoading = createAction(
  '[Folder] Set folder Loading',
  props<{ key:any, loading: boolean }>()
);

export const setAfterSuccess = createAction(
  '[After Folder Effect] reset success parameter'
);

export const toggleArchiveModal = createAction(
  '[Folder action - Archive ] Toggle archive / restore modal',
    props<{ archive:any }>()
);

export const archiveFolder = createAction(
    '[Folder effect - Archive ] Make archiving http request',
    props<{ id:any }>()
);

export const archiveFolderSuccess = createAction(
    '[Folder Archive Result] folder archived',
    props<{ folder:any,success:any }>()
);

export const archiveFolderFailed = createAction(
  '[Folder Archive Result] folder archiving failed',
  props<{ error:any }>()
);

export const restoreFolder = createAction(
  '[Folder effect - Archive ] Make restoring http request',
  props<{ id:any }>()
);

export const restoreFolderSuccess = createAction(
  '[Folder restore Result] folder restored',
  props<{ folder:any,success:any }>()
);

export const restoreFolderFailed = createAction(
'[Folder restore Result] folder restoring failed',
props<{ error:any }>()
);

export const spliceFolder = createAction(
  '[Folders Tree] splice folder',
  props<{ id:any, source:any, destination:any }>()
);

export const restoreSplicedFolder = createAction(
  '[Folders Tree] restore spliced folder',
  props<{ id:any, source:any }>()
);

export const replaceFolder = createAction(
  '[Folders Tree] replace folder in state',
  props<{ operation:any }>()
);

export const selectedTargetMoveFolder = createAction(
  '[Folder tree] select target move folder',
  props<{ error: any }>()
);



export const archiveProject = createAction(
  '[Project effect - Archive ] Make archiving http request',
  props<{ id:any,key:any }>()
);

export const archiveProjectSuccess = createAction(
  '[Project Archive Result] project archived',
  props<{ project:any }>()
);

export const archiveProjectFailed = createAction(
'[Project Archive Result] project archiving failed',
props<{ error:any }>()
);

export const restoreProject = createAction(
'[Project effect - Archive ] Make restoring http request',
props<{ id:any,key:any }>()
);

export const restoreProjectSuccess = createAction(
'[Project restore Result] project restored',
props<{ project:any}>()
);

export const restoreProjectFailed = createAction(
'[Project restore Result] project restoring failed',
props<{ error:any }>()
);




export const FoldersActions={
    selectedTargetMoveFolder,
    toggleUpsertFolder,
    createFolder,
    createDraftFolderSuccess,
    createCompletedFolderSuccess,
    createFolderFailed,
    toggleImportProject,
    importProject,
    setLoading,

    fetchFolders,
    fetchDraftSuccess,
    fetchCompletedSuccess,
    fetchArchivedSuccess,
    fetchFoldersFailed,
    setFolderLoading,

    trackLastNode,
    createSubFolder,
    editFolder,
    upsertFolderSuccess,
    setAfterSuccess,
    toggleTrackChanges,
    toggleArchiveModal,
    archiveFolder,
    archiveFolderSuccess,
    archiveFolderFailed,
    spliceFolder,
    restoreFolder,
    restoreFolderSuccess,
    restoreFolderFailed,
    restoreSplicedFolder,
    replaceFolder,
    dismissResult,
    archiveProject,
    archiveProjectSuccess,
    archiveProjectFailed,
    restoreProject,
    restoreProjectSuccess,
    restoreProjectFailed,
}