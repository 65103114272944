import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '@auth0/auth0-angular';
import { VesselsOverviewState } from '../reducers/vessels-overview.reducers';

export const selectVOState = createFeatureSelector<AppState,VesselsOverviewState>('vesselsOverview');

export const selectCanShowVesselDrawer = createSelector(
    selectVOState,
    (state: VesselsOverviewState) => state.canShowVesselDrawer
);

export const selectViewType = createSelector(
    selectVOState,
    (state: VesselsOverviewState) => state.viewType
);

export const selectActiveVessels = createSelector(
    selectVOState,
    (state: VesselsOverviewState) => state.activeVessels
);

export const selectArchivedVessels = createSelector(
    selectVOState,
    (state: VesselsOverviewState) => state.archivedVessels
);

export const selectTab = createSelector(
    selectVOState,
    (state: VesselsOverviewState) => state.selectedTab
);

export const selectError = createSelector(
    selectVOState,
    (state: VesselsOverviewState) => state.error
);

export const selectLastArchived = createSelector(
    selectVOState,
    (state: VesselsOverviewState) => state.lastArchivedVessel
);

export const selectLastRestored = createSelector(
    selectVOState,
    (state: VesselsOverviewState) => state.lastRestoredVessel
);

export const selectActiveStats = createSelector(
    selectVOState,
    (state: VesselsOverviewState) => state.activeStats
);

export const selectArchivedStats = createSelector(
    selectVOState,
    (state: VesselsOverviewState) => state.archivedStats
);

export const selectCountActive = createSelector(
    selectVOState,
    (state: VesselsOverviewState) => state.activeVessels.length
);

export const selectCountArchived = createSelector(
    selectVOState,
    (state: VesselsOverviewState) => state.archivedVessels.length
);

export const selectNewVesselData = createSelector(
    selectVOState,
    (state: VesselsOverviewState) => state.newVesselData
);

export const selectCreatedVessel = createSelector(
    selectVOState,
    (state: VesselsOverviewState) => state.createdVessel
);

export const selectLoadingActive = createSelector(
    selectVOState,
    (state: VesselsOverviewState) => state.loadingActive
);

export const selectLoadingArchived = createSelector(
    selectVOState,
    (state: VesselsOverviewState) => state.loadingArchived
);
