import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DanishShippingRoutingModule } from './danish-shipping-routing.module';
import { DanishShippingComponent } from './danish-shipping.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { VesselsHeaderComponent } from './components/vessels-header/vessels-header.component';
import { DsButtonComponent } from './components/ds-button/ds-button.component';
import { VesselDrawerComponent } from './components/vessel-drawer/vessel-drawer.component';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ResultsOverviewComponent } from './results-overview/results-overview.component';
import { EmissionNavigatorComponent } from './emission-navigator/emission-navigator.component';
import { VesselLifecycleScreeningComponent } from './vessel-lifecycle-screening/vessel-lifecycle-screening.component';
import { VesselLifecycleScreeningResultComponent } from './components/vessel-lifecycle-screening-result/vessel-lifecycle-screening-result.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SessionModalComponent } from './components/session-modal/session-modal.component';
import { CardItemComponent } from './components/card-item/card-item.component';
import { ListItemComponent } from './components/list-item/list-item.component';
import { OverviewStatsComponent } from './components/overview-stats/overview-stats.component';
import { MaterialModalComponent } from './components/material-modal/material-modal.component';
import { CustomScenarioComponent } from './custom-scenario/custom-scenario.component';

@NgModule({
  declarations: [
    DanishShippingComponent,
    DashboardComponent,
    VesselsHeaderComponent,
    DsButtonComponent,
    VesselDrawerComponent,
    ResultsOverviewComponent,
    EmissionNavigatorComponent,
    VesselLifecycleScreeningComponent,
    VesselLifecycleScreeningResultComponent,
    SessionModalComponent,
    CardItemComponent,
    ListItemComponent,
    OverviewStatsComponent,
    MaterialModalComponent,
    CustomScenarioComponent,
    SessionModalComponent 
  ],
  imports: [
    CommonModule,
    DanishShippingRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class DanishShippingModule { }
