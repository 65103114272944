import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ProjectsActions } from '../../store/actions/project.actions';
import { Store } from '@ngrx/store';
import { ProjectsState } from '../../store/reducers/project.reducer';
import { FoldersState } from '../../store/reducers/folder.reducer';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { selectError, selectsLastSelectedNode } from '../../store/selectors/folder.selectors';
import { selectAssignedProject, selectResultProjectt } from '../../store/selectors/project.selectors';
import { debounceTime, filter, take } from 'rxjs/operators';
import { FoldersActions } from '../../store/actions/folder.actions';

@Component({
  selector: 'app-move-project-modal',
  templateUrl: './move-project-modal.component.html',
  styleUrls: ['./move-project-modal.component.scss']
})
export class MoveProjectModalComponent implements OnInit {

  @Output() result = new EventEmitter<{ success: any}>();

  result$:Observable<any>;
  error$:Observable<any>;

  lastSelectedNode:any=null;
  projectToMove:any=null;
  loading:boolean=false;

  subscription:Subscription;

  constructor(
    private projectsStore: Store<{ projects: ProjectsState }>,
    private foldersStore: Store<{ folders: FoldersState }>,
  ) { 
    this.subscription = combineLatest([
      this.foldersStore.select(selectsLastSelectedNode),
      this.projectsStore.select(selectResultProjectt),
    ]) .pipe(debounceTime(10)).subscribe(([lastSelectedNode, projectToMove]) => {
      this.lastSelectedNode=lastSelectedNode;
      this.projectToMove=projectToMove;
    });
    this.result$ = this.projectsStore.select(selectAssignedProject);
    this.error$ = this.projectsStore.select(selectError);
  }

  ngOnInit(): void {
    this.loading=false;
  }

  close(){
    this.projectsStore.dispatch(ProjectsActions.showMoveProjectModal({canShow:false}))
  }

  moveProject(){
    this.loading=true;
    this.projectsStore.dispatch(ProjectsActions.moveToFolder({project:{folderId:this.lastSelectedNode,projectId:this.projectToMove.id}}))
    this.checkForResults()
  }



  checkForResults(){
    this.result$.pipe(
      filter(result => !!result), 
      take(1)
    ).subscribe(result => {
      this.projectsStore.dispatch(ProjectsActions.showMoveProjectModal({canShow:false}))
      this.result.emit({ success: "Project Moved successfully"});
    });

    this.error$.pipe(
      filter(error => !!error), 
      take(1)
    ).subscribe(error => {
      this.result.emit({ success: null});
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
