import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HelperService } from './helper.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuantifyService {
  
  environmentUrl = "Debug api";
  constructor(
    private http: HttpClient,
    private helperService: HelperService
  ) {
    this.environmentUrl = environment.apiUrl;
  }

  public getRecentProjects(companyId: number): any {
    return this.http.get<any>(
      `${this.environmentUrl}/api/quantify/recentProjects/` + companyId,
      this.helperService.headers()
    );
  }

  public getRecentprojects(companyId: number): Observable<any> {
    return this.http.get(
      `${this.environmentUrl}/api/quantify/${companyId}/recent` ,
      this.helperService.headers()
    );
  }

  public searchProject(companyId: number, screeningName:string): Observable<any> {
    return this.http.get(
      `${this.environmentUrl}/api/quantify/${companyId}/search/${screeningName}` ,
      this.helperService.headers()
    );
  }

  public getFoldersOfCompany(getFolderDto):Observable<any>{
    return this.http.post<any>(
      `${this.environmentUrl}/api/quantify/getFoldersTree`,getFolderDto,
      this.helperService.headers()
    );
  }

  public archiveFolder(id):Observable<any>{
    return this.http.get<any>(
      `${this.environmentUrl}/api/quantify/folders/archive/`+id,
      this.helperService.headers()
    );
  }

  public restoreFolder(id):Observable<any>{
    return this.http.get<any>(
      `${this.environmentUrl}/api/quantify/folders/restore/`+id,
      this.helperService.headers()
    );
  }
  public getScreeningByStatus(getScreeningsByStatusDto):Observable<any>{
    return this.http.post<any>(
      `${this.environmentUrl}/api/quantify/getScreeningByStatus`,getScreeningsByStatusDto,
      this.helperService.headers()
    );
  }

  public getScreeningByFolder(getScreeningByFolderDto):Observable<any>{
    return this.http.post<any>(
      `${this.environmentUrl}/api/quantify/getScreeningByFolder`,getScreeningByFolderDto,
      this.helperService.headers()
    );
  }
  public archiveProject(id):Observable<any>{
    return this.http.get<any>(
      `${this.environmentUrl}/api/quantify/screenings/archive/${id}`,
      this.helperService.headers()
    );
  }

  public restoreProject(id):Observable<any>{
    return this.http.get<any>(
      `${this.environmentUrl}/api/quantify/screenings/restore/${id}`,
      this.helperService.headers()
    );
  }
}
