<div class="vessels-header-container">
    <div class="nav-map-container">
        <img class="home" src="../../../../assets/danish-shipping-assets/home.svg" alt="">
        <img class="arrow" src="../../../../assets/danish-shipping-assets/arrow-right.svg" alt="">
        <label class="nav-item">Emission Navigator</label>
        <img class="arrow" src="../../../../assets/danish-shipping-assets/arrow-right.svg" alt="">
        <label class="nav-item nav-selected">Vessel Lifecycle Screening</label>
    </div>
    <div class="header-container">
        <div class="descriptive-container">
            <div class="title">Vessel Lifecycle Simulator</div>
            <div class="description">This digital tool provides a standardized way to assess lifecycle emissions for various vessel types. It allows users to explore the impact of different fuels, technologies, and efficiency measures using reliable, pre-loaded data.</div>
        </div>
        <div class="action-container">
            <app-ds-button (click)="onAddVesselClick()"></app-ds-button>
        </div>
    </div>
</div>