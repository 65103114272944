<div class="project-details">
    <div class="header-p">
        <span class="header-p-left">

            <img src="../../../assets/mail-forward.svg" style="width:28px !important" alt="" placement="top" ngbTooltip="Share" (click)="share()">

            <img src="../../../assets/duplicate.svg" alt="" style="margin-left: 10px;" placement="top" ngbTooltip="Duplicate" (click)="duplicate()">

            <img src="../../../assets/move.png" style="width: 30px !important;margin-top: 2px;margin-left: 10px;" alt="" placement="top" ngbTooltip="Move to folder" (click)="move()">

            <img src="../../../assets/books.svg" style="width:28px !important" alt="" style="margin-left: 10px;" placement="top" ngbTooltip="Archive" (click)="archive()">
            <div *ngIf="downloadingFile==true" class="spinner-border text-secondary">
            </div>
            <span class="dd-with-dIcon p-0" id="dropdownMenuButton"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" 
                placement="top" ngbTooltip="Download" *ngIf="downloadingFile==false">
                <img src="../../../assets/file_download.svg" class="dropleft ml-1" alt="">
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" 
                    style="position: absolute; left:auto !important; right: 0 !important; margin-left: -40px;"
                    #dropdownMenu placement="bottom-left" display="dynamic">
                    <div class="dropdown-item" (click)="downloadSheet()">
                        <div class="label green">
                            <em class="icon-file-earmark-spreadsheet"></em>
                            <label>Data Sheet</label>
                        </div>
                        <ng-template #popupContent>
                            <div class="pop-container">
                                <div class="pop-header d-flex">
                                    <em class="icon-info bigger-info"></em>
                                    <label class="pop-title">
                                        Data Sheet
                                    </label>
                                </div>
                                <p class="info-description">
                                    Provides the main results in an excel format including the total carbon
                                    footprint,
                                    the contribution of each of the life cycle stages and a breakdown of the
                                    carbon
                                    footprint of all parameters included in the project.
                                </p>
                            </div>
                        </ng-template>
                        <em class="icon-info hovered select-item-icon" animation='false' container="body"
                            [ngbPopover]="popupContent" triggers="hover" placement="left"></em>
                    </div>
                    <div class="dropdown-item" (click)="downloadPdf()">
                        <div class="label dark-blue">
                            <em class="icon-award-fill"></em>
                            <label>Climate Assessment Report</label>
                        </div>
                        <ng-template #popupContent1>
                            <div class="pop-container">
                                <div class="pop-header d-flex">
                                    <em class="icon-info bigger-info"></em>
                                    <label class="pop-title">
                                        Climate Assessment Report
                                    </label>
                                </div>
                                <p class="info-description">
                                    Provides a project outline that proves that the organization has carried out
                                    an
                                    assessment of the CO<sub>2</sub> emissions of a specific product or system.
                                    This document includes all key project information and the assessment
                                    results, such
                                    as total carbon footprint,
                                    most impacting life cycle stages and most impactful parameters.
                                    The assessment summary does not entitle any validation but is proof of
                                    having
                                    conducted an LCA-based assessment.
                                </p>
                            </div>
                        </ng-template>
                        <em class="icon-info hovered select-item-icon" animation='false' container="body"
                            [ngbPopover]="popupContent1" triggers="hover" placement="left"></em>
                    </div>
                    <div class="dropdown-item no-bb" (click)="downloadCertificate()">
                        <div class="label light-blue">
                            <em class="icon-accomplished"></em>
                            <label>Certificate of Validation</label>
                        </div>
                        <ng-template #popupContent2>
                            <div class="pop-container">
                                <div class="pop-header d-flex">
                                    <em class="icon-info bigger-info"></em>
                                    <label class="pop-title">
                                        Project Validation
                                    </label>
                                </div>
                                <p class="info-description">
                                    By requesting project validation an external review of your project will be
                                    conducted by the Environmental Engineering Department at ReFlow.
                                    All project stages are reviewed including the life cycle stages, the
                                    inventory and
                                    provided data,
                                    and the project assessment outcomes to correlate and comply with the
                                    international
                                    life cycle assessment ISO standards.
                                    The users will receive a validation report from ReFlow as the documentation
                                    for the
                                    validity of their calculations.
                                </p>
                            </div>
                        </ng-template>
                        <em class="icon-info hovered select-item-icon" animation='false' container="body"
                            [ngbPopover]="popupContent2" triggers="hover" placement="left"></em>
                    </div>
                </div>
            </span>
            <span class="dd-container" ngbDropdown *ngIf="projectHistory!=null && projectHistory.length!=0">
                <span class="dd-with-dIcon" style="margin-left: 10px;" ngbDropdownToggle style="width: 100%; height: 100%; display:flex; align-items: center; justify-content: center; -webkit-appearance: none;
            -moz-appearance: none;">
                    <img src="../../../assets/notification.png" class="dropleft ml-1 notification" alt="">
                </span>
                <div class="dropdown-menu ddr-notification" ngbDropdownMenu aria-labelledby="dropdownBasic1"
                    style="width: 300px;" placement="bottom-left" display="dynamic">
                    <div class="dropdown-item" *ngFor="let notification of projectHistory">
                        <div class="head">
                            <em *ngIf="notification.status=='received'" class="icon-validation validation"></em>
                            <em *ngIf="notification.status=='in progress'" class="icon-in-progress orange"></em>
                            <em *ngIf="notification.status=='corrected'" class="icon-edit purple"></em>
                            <em *ngIf="notification.status=='completed'"
                                class="icon-validation-completed blue"></em>
                            <label>{{notification.updatedAt | date:'EEEE, MMMM d, y'}} </label>
                        </div>
                        <p>{{notification.updateDescription}}</p>
                        <p *ngIf="notification.status=='corrected'">click <a
                                href="{{validationRequest.correctionFile}}" download>here</a> to download your
                            correction scheme</p>

                    </div>
                </div>
            </span>
        
        </span>
        <img src="../../../assets/open.svg" alt="" style="float:right;" class="open" [routerLink]="['./screening',currentProject.id]">


    </div>
    <div class="body">
        <label for="" class="title">
            {{currentProject?.name}}
        </label>

        <img src="../../../assets/r_p_completed_projects@2x.png" alt="" class="logo">
        <label for="" class="info">
            Information
        </label>
    </div>
    <div class="info-item">
        <label for="" class="label">Product Name:</label>
        <div class="status-container">
            <label for="" class="status stat-date">{{currentProject?.product?.name}}</label>
        </div>
    </div>
    <div class="info-item">
        <label for="" class="label">Product Type:</label>
        <div class="status-container">
            <label for="" class="status stat-date">{{currentProject?.product?.type}}</label>
        </div>
    </div>
    <div class="info-item">
        <label for="" class="label">Assessment Date:</label>
        <div class="status-container">
            <label for="" class="status stat-date">{{currentProject?.creationDate|date:'MMM d, y'}}</label>
        </div>
    </div>
    <div class="info-item no-bb">
        <label for="" class="label">Validation status:</label>
        <ng-template #popupContent5>
            <div class="pop-container1">
                <div class="pop-header d-flex">
                    <label class="pop-title1">
                        Project Validation
                    </label>
                </div>
                <p class="info-description">
                    By requesting project validation an external review of your project will be conducted by the
                    Environmental Engineering Department at ReFlow. All project stages are reviewed including
                    the life cycle stages, the inventory and provided data, and the project assessment outcomes
                    to correlate with the international life cycle assessment ISO standards. The
                    users will receive a validation report from ReFlow as the documentation for the validity of
                    their calculations.
                </p>
            </div>
        </ng-template>
        <em class="icon-info validation-info" style="margin-left: 106px; position: absolute;" animation='false'
            container="body" [ngbPopover]="popupContent5" triggers="hover" placement="top"></em>
            <div class="status-container" *ngIf="validationRequest$|async as validationrequest">
                <button class="validation-button" *ngIf="validationrequest.request.id==0" (click)="toggleValidationModal()"> 
                    <label class="validation-button-text">$ Validate Project</label>
                </button>
                <label *ngIf="validationrequest.request.id!=0" class="validation-status"><em class="{{statusIcon}}"></em> {{requestStatus}}</label>
            </div>
    </div>

</div>