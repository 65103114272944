import { Component, Output,Input, OnInit, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Screening } from 'src/app/models/screening.model';
import { CompareService } from 'src/app/services/compare.service';
import { SalesService } from 'src/app/services/sales.service';
import { ScreeningService } from 'src/app/services/screening.service';

@Component({
  selector: 'app-compare-projects',
  templateUrl: './compare-projects.component.html',
  styleUrls: ['./compare-projects.component.scss']
})
export class CompareProjectsComponent implements OnInit {
public companyId: number;
search_array: Screening[];
selected_projects: any[] = [null,null,null];
un_selected_projects: number[] = [];
projects_to_add: any[] = [];
projects_to_remove: any[] = [];
final_projects_to_add: any[] = [];
final_projects_to_remove: any[] = [];
public modal_screenings: any[];
public initial_screening: Screening[];
public user_input = '';
showModal = false;
message  = '';
public displaySearch=true;
public highlightedSearchItem=null;
public searchCollapse=null;
public showMoreCollapse:any[]=[false,false,false];
public showNoteInfo=false;
public showDanger=false;
public searchFocus=false;
public filterdown=true;
public comparedProjects:any[];
@Output() close: EventEmitter<any> = new EventEmitter();
public showMoreView=false;
public selectedProject=null;
constructor(
    private screeningService: ScreeningService,
    private compareService: CompareService,
    private router: Router,
    private salesService: SalesService
    ) { 
        
}   

ngOnInit(): void {    
    this.companyId = Number(localStorage.getItem("company_id"));
    this.getScreenings(); 
    this.getComparisonHistory();    
}  
    cancel($event) {
    this.close.emit(null);
}

  public getScreenings(): void {
    this.screeningService.getCompletedScreeningByCompanyId(Number(localStorage.getItem("company_id"))).subscribe(res => {
        this.modal_screenings = res;
        this.modal_screenings = this.modal_screenings.filter(screening => screening.finished == true);
        this.initial_screening=this.modal_screenings;
    });
  }

  public getComparisonHistory(): void {
    this.compareService.getComparisonHistory(Number(localStorage.getItem("company_id"))).subscribe(res=>{
      this.comparedProjects = res;
    });
  }

  public openComparison(selectedComparison:any): void {
    let projects = [selectedComparison.firstProjectId,selectedComparison.secondProjectId,selectedComparison.thirdProjectId];
    localStorage.setItem('compare',JSON.stringify(projects));
      this.router.navigate([
        "/homeServiceProvider",
        Number(localStorage.getItem('company_id')),
        "compare",
      ]
    );
  }

  public downloadComparisonSummary(selectedComparison:any): void {
    this.compareService.getProofOfCompare([selectedComparison.firstProjectId,selectedComparison.secondProjectId,selectedComparison.thirdProjectId],"Comparison "+ Date.now().toString()).subscribe(result=>{
      this.screeningService.DownloadFile(result, "testCompare"+".pdf", 'application/pdf')
    });
  }

  public selectScreening(screening_id:number)
  {
    if(this.selected_projects.indexOf(screening_id) == -1)
      {
       this.selected_projects.push(screening_id);       
      }else
      {
        const index = this.selected_projects.indexOf(screening_id, 0);
        if (index > -1) {
          this.selected_projects.splice(index, 1);
        }
      } 
     
  }
  public unselectScreening(screening_id:number)
  {     
    if(this.un_selected_projects.indexOf(screening_id) == -1)
      {
       this.un_selected_projects.push(screening_id);       
      }else
      {
        const index = this.un_selected_projects.indexOf(screening_id, 0);
        if (index > -1) {
          this.un_selected_projects.splice(index, 1);
        }
      }            
  }

  public addScreening()
  {     
    const def=this.selected_projects?.forEach(value => { 
      this.projects_to_add = this.modal_screenings.filter((screening: Screening) => screening.id === value);
      this.final_projects_to_add.push(this.projects_to_add[0]);  
      var index = this.modal_screenings.map(x => {return x.id;}).indexOf(this.projects_to_add[0].id);    
      this.modal_screenings.splice(index, 1);
      this.initial_screening.splice(this.initial_screening.map(x => {return x.id;}).indexOf(this.projects_to_add[0].id), 0);
    });
    this.selected_projects = [];
  }
  public removeScreening()
  {      
    this.un_selected_projects.forEach(value=> { 
    this.projects_to_remove = this.final_projects_to_add.filter((screening: Screening) => screening.id === value);      
    this.final_projects_to_remove.push(this.projects_to_remove[0]);      
    });  
    this.un_selected_projects = [];    
    this.modal_screenings = this.modal_screenings.concat(this.final_projects_to_remove);
    this.initial_screening = this.initial_screening.concat(this.final_projects_to_remove);    
    this.final_projects_to_remove.forEach(element => { 
    var index = this.final_projects_to_add.map(x => {return x.id;}).indexOf(element.id);    
    this.final_projects_to_add.splice(index, 1); 
    this.final_projects_to_remove = [];
    });    
  }

    public searchScreening()
    {
      this.modal_screenings = this.initial_screening.filter((screening: Screening) => screening.name.toLowerCase().includes(this.user_input.toLowerCase()));      
      //this.modal_screenings =this.sample
    }

    public compareScreenings()
    {
      let count =this.selected_projects.filter(x=>x==null);
      if(count.length>=2)
      { 
        this.showNoteInfo=true;
        setTimeout(() => {
          this.showNoteInfo=false;
        }, 8000);
      }
      else if(this.selected_projects[0]==null){
        this.showDanger=true;
        setTimeout(() => {
          this.showDanger=false;
        }, 8000);
      }
      else{
        localStorage.setItem('compare',JSON.stringify(this.selected_projects.filter(x=>x!=null).map(project => project.id)));
      this.router.navigate([
        "/homeServiceProvider",
        Number(localStorage.getItem('company_id')),
        "compare",
      ]);
      }          
    }
    closeErrorModal()
    {
      this.showModal = false;
    }
    public selectToBeCompared(id){
      if(this.selected_projects[1]==null){
        this.selected_projects[1]=id;
      }else if(this.selected_projects[2]==null){
        this.selected_projects[2]=id;
      }
    }
    public selectionErrorAlert=false;
    public canBeSelected(item,idx){
      if(this.selected_projects.includes(item)){
          this.selectionErrorAlert=true;
          setTimeout(() => {
              this.selectionErrorAlert=false;
        }, 8000);
      }else{
        if(idx==0){
          this.selected_projects[0]=item
        }else{
          this.selectToBeCompared(item)
        }
      }
    }
    public reverseArrayOrder(): any[] {
    return this.comparedProjects.reverse();
  }  

  public showViewMoreModal(event){
    this.showMoreView=false;
  }
}
