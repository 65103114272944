import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProjectsState } from '../../store/reducers/project.reducer';
import { ProjectsActions } from '../../store/actions/project.actions';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { selectsLastSelectedNode, selectsRootOfLastSelectedNode } from '../../store/selectors/folder.selectors';
import { FoldersState } from '../../store/reducers/folder.reducer';
import { selectResultProjectt } from '../../store/selectors/project.selectors';
import { ToastrService } from 'ngx-toastr';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-move-folder',
  templateUrl: './move-folder.component.html',
  styleUrls: ['./move-folder.component.scss']
})
export class MoveFolderComponent implements OnInit {
  @Input() selectedProject:any=null;
  rootOfLastSelectedNode$:Observable<any>;
  lastSelectedNode$:Observable<any>;
  projectToMove$:Observable<any>;

  rootOfLastSelectedNode:any=null;
  lastSelectedNode:any=null;
  projectToMove:any=null;

  subscription:Subscription;

  constructor(
    private projectsStore: Store<{ projects: ProjectsState }>,
    private foldersStore: Store<{ folders: FoldersState }>,
    private toasterService: ToastrService,
  ) { 

    this.subscription = combineLatest([
      this.foldersStore.select(selectsRootOfLastSelectedNode),
      this.foldersStore.select(selectsLastSelectedNode),
      this.projectsStore.select(selectResultProjectt),
    ]) .pipe(debounceTime(10)).subscribe(([rootOfLastSelectedNode, lastSelectedNode, projectToMove]) => {
      this.rootOfLastSelectedNode=rootOfLastSelectedNode;
      this.lastSelectedNode=lastSelectedNode;
      this.projectToMove=projectToMove;
    });
    
  }

  ngOnInit(): void {
  }

  close(){
    this.projectsStore.dispatch(ProjectsActions.toggleMoveProject({canMoveProject:false}))
  }

  showMoveProjectModal(){
    if(this.rootOfLastSelectedNode=='Draft' && this.projectToMove.finished==false){

      this.projectsStore.dispatch(ProjectsActions.showMoveProjectModal({canShow:true}))
      
    }else if(this.rootOfLastSelectedNode=='Completed' && this.projectToMove.finished==true){

      this.projectsStore.dispatch(ProjectsActions.showMoveProjectModal({canShow:true}))
      
    }else{
      if(this.rootOfLastSelectedNode==null){
        this.toasterService.info("A folder must be selected");
      }else if(this.projectToMove.finished==false&&this.rootOfLastSelectedNode=='Completed'){
        this.toasterService.info("A folder from Draft directory must be selected");
      }else if(this.projectToMove.finished==true&&this.rootOfLastSelectedNode=='Draft'){
        this.toasterService.info("A folder from Completed directory must be selected");
      }
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
