<div class="main">
    <div class="frame-157">
        <div class="rectangle-6374">
            <img src="../../../../assets/danish-shipping-assets/ship.png" alt="Asset Image" class="asset-image">
        </div>
        <div class="frame-2121314955">
            <div class="text-input">
                <p class="text-title">Maersk MC-Kinney Moller</p>
            </div>
            <div class="frame-163">
                <div class="section-label">
                    <p class="text-section-label">IMO</p>
                    <p class="supporting-text-section-label">756748393</p>
                </div>
                <div class="section-label">
                    <p class="text-section-label">Vessel type</p>
                    <p class="supporting-text-section-label">Cargo</p>
                </div>
            </div>
            <div class="frame-164">
                <div class="section-label">
                    <p class="text-section-label">DWT</p>
                    <p class="supporting-text-section-label">74,638 tonnes</p>
                </div>
                <div class="section-label">
                    <p class="text-section-label">Fuel type</p>
                    <p class="supporting-text-section-label">E-methanol</p>
                </div>
            </div>
            <div class="frame-165">
                <div class="section-label">
                    <p class="text-section-label">Recycling location</p>
                    <p class="supporting-text-section-label">India</p>
                </div>
                <div class="section-label">
                    <p class="text-section-label">Recycling method</p>
                    <p class="supporting-text-section-label">Plasma Cutting</p>
                </div>
            </div>
            <div class="frame-166">
                <div class="section-label">
                    <p class="text-section-label">Vessel Lifetime</p>
                    <p class="supporting-text-section-label">10 years</p>
                </div>
            </div>
        </div>
    </div>
    <div class="frame-162">
        <div class="frame-161">
            <div class="frame-159">

                <p class="life-cycle-screening">Life cycle screening:</p>
                <div style="margin-right: 20px;"> 
                    <span class="life-cycle-value">8,956</span>
                    <span class="life-cycle-unit">tCO2eq</span>
                </div>
                
            </div>
            <div class="text-input-container">
                <div class="text-input dark-blue" style="margin-left: 25px;"></div>
                <div class="text-input aqua-blue"></div>
                <div class="text-input bright-green"></div>
            </div>
            <div class="square-container">
                <div class="scope-indicator">
                    <div class="indicator-square"></div>
                    <span class="scope-label">Scope 1</span>
                    <span class="percentage">(33%)</span>
                </div>
                <div class="scope-indicator">
                    <div class="indicator-square-aquaBlue"></div>
                    <span class="scope-label">Scope 2</span>
                    <span class="percentage">(33%)</span>
                </div>
                <div class="scope-indicator">
                    <div class="indicator-square-bright-green"></div>
                    <span class="scope-label">Scope 3</span>
                    <span class="percentage">(33%)</span>
                </div>
            </div>
            <div class="emission-container">
                <div class="emission-button">
                    <span class="emission-value">3,746</span>
                    <span class="emission-unit">tCO2eq</span>
                </div>
                <div class="emission-button">
                    <span class="emission-value">3,746</span>
                    <span class="emission-unit">tCO2eq</span>
                </div>
                <div class="emission-button">
                    <span class="emission-value">3,746</span>
                    <span class="emission-unit">tCO2eq</span>
                </div>
            </div>
            <div class="card-container">
                <div class="result-card">
                    <div class="card-content">
                        <div class="card-title">Baseline building scenario</div>
                        <p class="card-description">
                            This scenario represents the default set of assumptions and parameters for the vessel’s construction phase, providing a baseline for lifecycle screening calculations.
                        </p>
                        <div class="card-result">
                            <span class="result-value">Result :</span>
                            <span class="result-value">8,766</span>
                            <span class="result-unit">tCO2eq</span>
                        </div>
                    </div>
                </div>
                <div class="result-card">
                    <div class="card-content">
                        <div class="card-title">Baseline building scenario</div>
                        <p class="card-description">
                            This scenario represents the default set of assumptions and parameters for the vessel’s construction phase, providing a baseline for lifecycle screening calculations.
                        </p>
                        <div class="card-result">
                            <span class="result-value">Result :</span>
                            <span class="result-value">8,766</span>
                            <span class="result-unit">tCO2eq</span>
                        </div>
                    </div>
                </div>
                <div class="result-card">
                    <div class="card-content">
                        <div class="card-title">Baseline building scenario</div>
                        <p class="card-description">
                            This scenario represents the default set of assumptions and parameters for the vessel’s construction phase, providing a baseline for lifecycle screening calculations.
                        </p>
                        <div class="card-result">
                            <span class="result-value">Result :</span>
                            <span class="result-value">8,766</span>
                            <span class="result-unit">tCO2eq</span>
                        </div>
                    </div>
                </div>           
            </div>
        </div>
    </div>
    <div class="chart-container">
        <div class="description-text-chart-container">
            <div style="display: flex;flex-direction: column;">
                <p class="chart-title">Lifecycle Comparison</p>
                <p class="chart-text-description">This graphic presents the vessel’s lifecycle screening results, displaying emissions data across Scope 1, Scope 2, and Scope 3.</p>
            </div>
            <div>
                <div class="icon-buttons">
                    <button class="button-left">
                      <svg fill="#475467" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="icon">
                        <path d="M14.657 18.657a1 1 0 0 1-.707-.293l-5.657-5.657a1 1 0 0 1 0-1.414l5.657-5.657a1 1 0 0 1 1.414 1.414L10.414 12l4.95 4.95a1 1 0 0 1-.707 1.707z"></path>
                      </svg>
                    </button>
                    <button class="button-right">
                      <svg fill="#475467" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="icon">
                        <path d="M9.343 18.657a1 1 0 0 1-.707-1.707l4.95-4.95-4.95-4.95a1 1 0 0 1 1.414-1.414l5.657 5.657a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 0 1-.707.293z"></path>
                      </svg>
                    </button>
                  </div>
                  
            </div>
        </div>
    </div>

    <div class="chart-container">
        <div class="description-text-chart-container">
            <div style="display: flex;flex-direction: column;">
                <p class="result-card-title">Baseline building scenario</p>
            </div>
        </div>
        <div style="display: flex;padding: 24px;">
            <div style="display: flex;flex-direction: column;">
                <p class="label">Vessel Type</p>
                <p class="value">Cargo</p>
            </div>
            <div style="display: flex;flex-direction: column;margin-left: 50%;">
                <p class="label">DWT</p>
                <p class="value">65,757 tonnes</p>
            </div>
        </div>
        <div style="display: flex;padding: 24px;">
            <div style="display: flex;flex-direction: column;">
                <p class="label">Built location</p>
                <p class="value">Ukraine</p>
            </div>
            <div style="display: flex;flex-direction: column;margin-left: 49.5%;">
                <p class="label">Length</p>
                <p class="value">555 M </p>
            </div>
        </div>
        <div style="display: flex;padding: 24px;">
            <div style="display: flex;flex-direction: column;">
                <p class="label">Beam</p>
                <p class="value">666 M</p>
            </div>
        </div>
        <div class="result-card-footer">
            <span class="footer-label">Results:</span>
            <span class="footer-value">8,766</span>
            <span class="footer-unit">tCO2eq</span>
          </div>
    </div>
    <div class="footer-page-divs">
        <div class="first-card">
            <p class="fuel-title">Fuel Climate Impact Assessment</p>
            <p class="label-option">Option</p>
            <p class="value-option">E-methanol</p>
            <div class="result-card-footer">
                <span class="footer-label">Results:</span>
                <span class="footer-value">8,766</span>
                <span class="footer-unit">tCO2eq</span>
              </div>
        </div>
        <div class="second-card">
            <p class="fuel-title">Recycling Method and Country</p>
            <div style="display: flex;">
                <div style="display: flex;flex-direction: column;">
                    <p class="label-option">Country</p>
                    <p class="value-option">India</p>
                </div>
                <div style="display: flex;flex-direction: column;margin-left: 25%;">
                    <p class="label-option">Method</p>
                    <p class="value-option">Oxy-fuel</p>
                </div>
            </div>


            <div class="result-card-footer">
                <span class="footer-label">Results:</span>
                <span class="footer-value">8,766</span>
                <span class="footer-unit">tCO2eq</span>
              </div>
        </div>
    </div>
</div>
