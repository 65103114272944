import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['../new-login.component.scss']
})
export class ErrorPageComponent implements OnInit {
  error:any="";
  error_v:any="";
  constructor(
    private auth: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.error=localStorage.getItem("error");
    if(localStorage.getItem("error")==undefined||localStorage.getItem("error")==null){
      sessionStorage.clear();
      localStorage.clear();
      this.router.navigate(['/login']);
    }
  }

  loginWithEmail() {
    this.auth.loginWithRedirect({
      authorizationParams: {
        connection: 'email',
        send: 'link',
      },
    });
  }

}
