import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { VesselsOverviewState } from '../../store/reducers/vessels-overview.reducers';
import { VesselsOverviewActions } from '../../store/actions/vessels-overview.actions';
import { FormBuilder, FormGroup } from '@angular/forms';
import { filter, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { selectCreatedVessel, selectError, selectNewVesselData } from '../../store/selectors/vessels-overview.selectors';

@Component({
  selector: 'app-vessel-drawer',
  templateUrl: './vessel-drawer.component.html',
  styleUrls: ['./vessel-drawer.component.scss']
})
export class VesselDrawerComponent implements OnInit {

  @Input() companyId:any=null;

  error$:Observable<any>;
  newVesselData$:Observable<any>;
  createdVessel$:Observable<any>;

  selectedItem: string = '--Select Type--';
  vesselBuilderForm!: FormGroup;
  imageUrl:any="../../../../../assets/danish-shipping-assets/ship.png";
  baseImageUrl:any="../../../../../assets/danish-shipping-assets/ship.png";

  showIMOerror:boolean=false;
  showLoadingVesselData:boolean=false;
  showLoadingCreate:boolean=false;

  vesselTypes:any=[
    "Bulk Carrier",
    "Bunkering Tanker",
    "Chemical/Oil Products Tanker",
    "Container Ship",
    "Crane Ship",
    "Crew Boat",
    "Crude Oil Tanker",
    "Deck Cargo Ship",
    "Dredger",
    "FSO",
    "General Cargo Ship",
    "N/A",
    "Offshore Support Vessel",
    "Passenger (Cruise) Ship",
    "Tug",
    "Yacht",
  ]
  
  constructor(
    private vesselsOverviewStore: Store<{ vesselsOverview: VesselsOverviewState }>,
    private fb: FormBuilder,
    private toasterService: ToastrService,
  ) { 
    this.error$ = vesselsOverviewStore.select(selectError);
    this.newVesselData$ = vesselsOverviewStore.select(selectNewVesselData);
    this.createdVessel$ = vesselsOverviewStore.select(selectCreatedVessel);
  }




  ngOnInit(): void {
    this.vesselBuilderForm = this.fb.group({
      IMO:[''],
      LifeTime:[''],
      VesselName:[''],
      imageUrl:[''],
      VesselType:[''],
      VesselDWT:[''],
      VesselLength:[''],
      VesselBeam:[''],
      companyId:[this.companyId],
    });
  }


  toggleDrawer() {
    this.vesselsOverviewStore.dispatch(VesselsOverviewActions.toggleVesselDrawer({canShow:false}))
  }
  
  selectItem(item: string) {
    this.vesselBuilderForm.get('VesselType')?.setValue(item);
    this.selectedItem=item;
  }

  importVesselData(){
    if(this.showLoadingVesselData==true){
      return;
    }
    if(this.vesselBuilderForm.get('IMO')?.value==""){
      this.showIMOerror=true;
      return;
    }
    this.showIMOerror=false;
    this.vesselsOverviewStore.dispatch(VesselsOverviewActions.importVesselData({imo:this.vesselBuilderForm.get('IMO')?.value}));
    this.showLoadingVesselData=true;
    this.checkImportIMO();
  }

  checkImportIMO(){
    this.error$.pipe(
      filter(error => !!error), 
      take(1)
    ).subscribe(error => {
      if(error=="import vessel failed"){
        this.toasterService.error('Import faild, please verify the specified IMO number.');
        this.showLoadingVesselData=false;
      }
    });
    this.newVesselData$.pipe(
      filter(newVesselData => !!newVesselData), 
      take(1)
    ).subscribe(result => {
        this.showLoadingVesselData=false;
        this.imageUrl=(result.imageUrl!=null||result.imageUrl!="")?result.imageUrl:this.baseImageUrl;
        const newValues = {
          IMO:result.imo,
          imageUrl:(result.imageUrl!=null||result.imageUrl!="")?result.imageUrl:"",
          VesselName:result.name,
          VesselType:result.type,
          VesselDWT:result.dwt,
          VesselLength:result.length,
          VesselBeam:result.beam,
        };
        this.vesselBuilderForm.patchValue(newValues);
        this.selectItem(result.type);
    });
  }

  createVessel(){
    let hasError:boolean=false;
    const data=this.vesselBuilderForm.value;
    for (const value of Object.values(data)) {
      if(value==''||value==null){
        hasError=true;
        break;
      }
    }
    if (hasError) {
      this.toasterService.error('All fields are required');
    } else {
      this.showLoadingCreate=true;
      this.vesselsOverviewStore.dispatch(VesselsOverviewActions.createVessel({vessel:data}))
      this.checkVesselCreated();
    }
  }



  checkVesselCreated(){
    this.error$.pipe(
      filter(error => !!error), 
      take(1)
    ).subscribe(error => {
      if(error=="create vessel failed"){
        this.toasterService.error('Vessel creation failed.');
        this.showLoadingCreate=false;
      }
    });
    this.createdVessel$.pipe(
      filter(createdVessel => !!createdVessel), 
      take(1)
    ).subscribe(result => {
      if(result!=null){
        this.showLoadingCreate=false;
        this.toasterService.success('Vessel created successfully.');
        this.vesselsOverviewStore.dispatch(VesselsOverviewActions.getActiveStats({id:this.companyId}));
        this.vesselsOverviewStore.dispatch(VesselsOverviewActions.getActiveVessels({id:this.companyId}));
        this.vesselsOverviewStore.dispatch(VesselsOverviewActions.getArchivedStats({id:this.companyId}));
        this.vesselsOverviewStore.dispatch(VesselsOverviewActions.getArchivedVessels({id:this.companyId}));
        this.toggleDrawer();
      }
    });
  }

}
