<div class="cards-section">
    <div class="separator-line mt-4"></div>
 
    <label for="" class="session-name">{{ sessionName }}</label>
 
     <!-- Content for Tab 2 --> 
     <div class="empty-state-block">
      
      <div class="empty-state-description-block">
        <div class="empty-state-txt-block">
          <label class="empty-state-title">To continue please add steel</label>
          <p class="empty-state-description">
           Select the type of steel, enter its weight, and choose the source country. These inputs will directly influence the lifecycle emissions calculations by accounting for the climate impact of steel production and transportation from the selected locations. Comparing different options allows to see how sourcing decisions affect the vessel's overall emissions.
          </p>
        </div>
        <div class="empty-state-add-option" (click)="openMaterialModal()">
          <img src="../../../assets/danish-shipping-assets/plus-icon.svg" alt="">
          <label for="">Add Option</label>
        </div>
      </div>
    </div>
 
    <div class="options-table-container">
      <table class="options-table">
        <thead>
          <tr>
            <th class="option-header">Material</th>
            <th class="option-header">Country</th>
            <th class="weight-header">Total weight</th>
            <th class="action-header"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Steel (low alloyed)</td>
            <td>India</td>
            <td class="add-weight">
              <input type="text" class="input-weight" placeholder="Add weight">
              <span class="unit">t</span>
            </td>
            <td class="actions">
              <span class="delete-link">Delete</span>
              <span class="edit-link">Edit</span>
            </td>
          </tr>
          <!-- <tr *ngFor="let option of options">
          
            <td class="select-oil">
              <span class="select-oil-link">  <img src="../../../assets/danish-shipping-assets/plus-blue-icon.svg" alt=""> {{ option.type }}</span>
            </td>
            <td class="add-weight">
              <input type="text" class="input-weight" placeholder="Add weight" [(ngModel)]="option.weight">
              <div ngbDropdown>
                <div class="dropdown-wrapper no-arrow" id="select-weight" ngbDropdownToggle>
                  <span class="drop-txt">{{ option.unit }}</span>
                  <img src="../../../../assets/danish-shipping-assets/drop-arrow-icon.svg" alt="" class="percentage-icon">
                </div>
                <div ngbDropdownMenu aria-labelledby="select-weight">
                  <div ngbDropdownItem (click)="option.unit = 't'">t</div>
                  <div ngbDropdownItem (click)="option.unit = 'm'">m</div>
                  <div ngbDropdownItem (click)="option.unit = 'kg'">kg</div>
                </div>
              </div>
            </td>
            <td class="actions">
              <span class="edit-link">Edit</span>
             
            </td>
          </tr> -->
        </tbody>
      </table>
 
      <!-- <div class="add-option-btn" (click)="addOption()"> -->
      <div class="add-option-btn" (click)="openMaterialModal()">
        <img src="../../../assets/danish-shipping-assets/plus-gray-icon.svg" alt="" class="plus-icon">
        <label class="add-option-txt">Add Option</label>
      </div>
 
     
    </div>
      
    
   <!-- Buttons Section -->
   <div class="buttons-section mb-4">
     
     <div class="save-draft-btn" >
         <img src="../../../assets/danish-shipping-assets/arrow-left-dark-icon.svg" alt="" class="">
         <label for="">Save as draft</label>
     </div>
     <div class="next-btn" >
         <label for="">Calculate</label>
     </div>
   </div>
 </div>