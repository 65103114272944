import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from 'src/app/services/company.service';
@Component({
  selector: 'app-one-time-redirection',
  templateUrl: './one-time-redirection.component.html',
  styleUrls: ['./one-time-redirection.component.css']
})
export class OneTimeRedirectionComponent implements OnInit {
  public companyId: number;
  public credit_to_refill: number;
  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute,
    private router: Router,private companyService:CompanyService) {
    
   }

  ngOnInit(): void {
    this.companyId = Number(localStorage.getItem("company_id"));
    this.credit_to_refill = Number(localStorage.getItem("credit_to_refill"));
    this.companyService.refillCredits({ companyId: this.companyId,credit_to_refill: this.credit_to_refill }).subscribe(res=>{
      this.router.navigate(["/homeServiceProvider", Number(localStorage.getItem("company_id")),"quantify"]);
    });
  }

}
