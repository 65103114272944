<div class="search-container">
    <div class="search-box" ngbDropdown [autoClose]="'outside'">
        <em class="icon-search icon-search-left"
            [ngClass]="{'icon-search-left-focus': focusSearchKeyWord==true||searchKeyWord!=''}"></em>
        <input type="text" placeholder="Search project" autocomplete="off" class="input-search"
            (focus)="focusSearchKeyWord=true" (blur)="processBlur()" 
            [(ngModel)]="searchKeyWord">
        <em class="icon-close close-search" style="top: 0px; right:0px;font-size: 23px;"
            [ngClass]="{'d-none': focusSearchKeyWord==false&&searchKeyWord==''}"
            (click)="searchKeyWord=''"></em>
        <div class="search-button" ngbDropdownToggle (click)="makeSearch()" [ngClass]="{'d-none': focusSearchKeyWord==false&&searchKeyWord==''}">
            <label for="">search</label>
        </div>
        <div *ngIf="searchList$ | async as searchList">
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="search-dropdown" >
                <div *ngIf="searchList && searchList.length > 0; else noItems">
                    <button *ngFor="let item of searchList" ngbDropdownItem (click)="editSceening(item)">
                        <img src="../../../assets/pi_draft_projects.svg" alt="">{{item?.name}}
                    </button>
                </div>
                <ng-template #noItems>
                    <div class="loading"  *ngIf=" loading$ | async">
                        <div class="spinner-border text-secondary" style="width: 3rem; height: 3rem;">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <p class="no-data" *ngIf="searchList.length == 0">No items found.</p>
                  </ng-template>
            </div>
            
        </div>
    </div>
</div>