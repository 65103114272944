<app-empty-folder-node  [folderName]="rootFolder" *ngIf="treeData==[]"></app-empty-folder-node>
<ng-container *ngIf="treeData!=[]" >
  <ng-container *ngFor="let node of treeData" >
    <ng-container *ngTemplateOutlet="recursiveNode; context: { $implicit: node }"></ng-container>
  </ng-container>
</ng-container>

<ng-template #recursiveNode let-node>
  <div style="position: relative;">
   
    <div class="dash-left"></div>
    
    <div class="folder-item" [ngClass]="{ 'selected-noode': node.id === (lastSelectedNode$ | async), 'target-move-folder': node.id === (lastSelectedNode$ | async) && canSelectTargetFolder }" [class.expanded]="node.expanded" (click)="toggleNode(node)">
      <div class="dash-bottom" *ngIf="node.parentId!=null"></div>
     
      <img src="../../../assets/archived-closed.png" alt="" *ngIf="node.folderName=='Archived'">
      <img src="../../../assets/fm_closed_folder@2x.png" alt="" *ngIf="!node.expanded&&node.folderName!='Archived'">
      <img src="../../../assets/fm_open_folder@2x.png" alt="" *ngIf="node.expanded&&node.folderName!='Archived'">
      <div class="folder-label-container">
        <label for="" class="folder-name">{{node.folderName}}</label>
        <div class="operations-container" *ngIf="node.parentId!=null && node.id==(lastSelectedNode$|async) && rootFolder!='Archived' ">
            <div class="operation  mr-2" (click)="$event.stopPropagation();archive(node)">
                <img src="../../../assets/books.svg" alt="">
                <label>Archive</label>
            </div>
            <label> | </label>
            <div class="operation  mr-2 ml-2" (click)="$event.stopPropagation();addFolder(node)">
                <img src="../../../assets/new_folder.svg">
                <label>Add folder</label>
            </div>
            <label> | </label>
            <div class="operation ml-2" style="margin-top: 3px;" (click)="$event.stopPropagation();editFolder(node)">
                <em class="fa fa-pencil"></em>
                <label>Edit folder</label>
            </div>

            <img src="../../../assets/Asset 41.png" alt="" *ngIf="false">
        </div>
        <div class="operations-container" *ngIf="node.parentId!=null && node.id==(lastSelectedNode$|async) && rootFolder=='Archived' ">
          <div class="operation  mr-2" (click)="$event.stopPropagation();restore(node)">
            <em class="fa fa-archive sub-icon"></em>
              <label>Restore</label>
          </div>

          <img src="../../../assets/Asset 41.png" alt="" *ngIf="false">
        </div>
    </div>
      <img *ngIf="false" class="folder-loading" src="../../../assets/Loading-icon-animation.svg" alt="" />
    </div>
    
    <!-- Recursively render children if the node is expanded -->
    <div *ngIf="node.expanded && node.children"  style="margin-left: 20px;">
      <ng-container *ngFor="let child of node.children">
        <ng-container *ngTemplateOutlet="recursiveNode; context: { $implicit: child }"></ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>