import { createAction, props } from '@ngrx/store';
import { UpsertFolderProps } from '../models/upsert-folder-props';
import { Folder } from '../models/folder';

export const loadRecentProjects = createAction(
    "[Recent Projects] load recent",
    props<{ companyId: any }>()
);

export const loadRecentProjectsSuccess = createAction(
    "[Recent Projects] load recent projects success",
    props<{ recentProjects: any }>()
);

export const loadRecentProjectsFailed = createAction(
    "[Recent Projects] load recent projects failed",
    props<{ error: any }>()
);


export const setLoading = createAction(
    '[Recent projects] Set Loading',
    props<{ loadingRecent: boolean }>()
);

export const createNewProject = createAction(
    '[New Project] create new empty project',
    props<{ emptyScreeningDto: any }>()
);

export const createNewProjectFailed = createAction(
    '[New Project] create new empty project failed',
    props<{ error: any }>()
);

export const createNewProjectSuccess = createAction(
    '[New Project] create new empty project success',
    props<{ newEmptyProject: any }>()
);

export const createScreeningLog = createAction(
    '[New Project] create screening log',
    props<{ screeningLogDto: any }>()
);

export const createScreeningLogFailed = createAction(
    '[New Project] create screening log',
    props<{ error: any }>()
);

export const searchProject = createAction(
    '[Search] find project by name keyword',
    props<{ search: any }>()
);

export const searchProjectSuccess = createAction(
    '[Search] search result set',
    props<{ searchResult: any }>()
);

export const searchProjectFailed = createAction(
    '[Search] no result found'
);

export const loadingSearch = createAction(
    '[Recent projects] Set Loading',
    props<{ loadingSearch: boolean }>()
);

export const loadMore = createAction(
    '[Projects panel] load more for root node',
    props<{ getScreeningsByStatusDto: any }>()
);
export const loadMoreSuccess = createAction(
    '[Projects panel] load more success',
    props<{ projects: any }>()
);

export const loadMoreFailed = createAction(
    '[Projects panel] load more failed',
    props<{ error: any }>()
);
export const trackLastProjectsDispatcher = createAction(
    "[Projects panel] track last selected folder", 
    props<{ node: any }>())
;

export const toggleLoadingMore = createAction(
    "[Projects panel] toggle loadingMore"
);
export const emptyAction = createAction('[Empty Action]');


export const loadMoreOfSubfolder = createAction(
    '[Projects panel - subfolder] load more for subfolder node',
    props<{ getScreeningByFolderDto: any }>()
);
export const loadMoreOfSubfolderSuccess = createAction(
    '[Projects panel - subfolder] load more subfolder success',
    props<{ projects: any }>()
);

export const loadMoreOfSubfolderFailed = createAction(
    '[Projects panel - subfolder] load more subfolder failed',
    props<{ error: any }>()
);

export const selectedProject = createAction(
    '[Projects panel - select project] select current project',
    props<{ project: any }>()
);

export const fetchSelectedProject = createAction(
    '[Projects panel - select project] get project data',
    props<{ project: any }>()
);

export const fetchSelectedProjectSuccess = createAction(
    '[Projects panel - select project] get project data success',
    props<{ project: any }>()
);

export const fetchSelectedProjectFailed = createAction(
    '[Projects panel - select project] get project data failed',
    props<{ error: any }>()
);

export const toggleMoveProject = createAction(
    '[Project details - move] toggle move project panel',
    props<{ canMoveProject: boolean }>()
);

export const moveToFolder = createAction(
    '[Move Folder Container] confirm move folder',
    props<{ project: any }>()
);
export const moveToFolderSuccess = createAction(
    '[Move Folder Container] move project success',
    props<{ project: any }>()
);
export const moveToFolderError = createAction(
    '[Move Folder Container] move project error',
    props<{ error: any }>()
);

export const showMoveProjectModal = createAction(
    '[Move Folder Container] toggle show move project modal',
    props<{ canShow: any }>()
);

export const clearProjects = createAction(
    '[Folder tree] clear projects of folder'
);

export const clearSubfolderProjects = createAction(
    '[Projects panel] clear SubfolderProjects'
);

export const toggleArchiveProject = createAction(
    '[Project details] toggle archive project',
    props<{ canShow: any }>()
);

export const spliceProject = createAction(
    '[Projects panel] remove project from list of projects',
    props<{ key: any, id:any }>()
);

export const shareProject = createAction(
    '[Project details] share project',
    props<{ id:any }>()
);

export const shareProjectSuccess = createAction(
    '[Project details] share project success',
    props<{ project:any }>()
);

export const shareProjectFailed = createAction(
    '[Project details] share project failed',
    props<{ error:any }>()
);


export const duplicateProject = createAction(
    '[Project details] duplicate project',
    props<{ id:any }>()
);
export const duplicateProjectSuccess = createAction(
    '[Project details] duplicate project success',
    props<{ project:any }>()
);
export const duplicateProjectFailed = createAction(
    '[Project details] duplicate project failed',
    props<{ error:any }>()
);

export const getValidationRequest = createAction(
    '[Project details] check for validation request',
    props<{ id:any }>()
);

export const getValidationRequestSuccess = createAction(
    '[Project details] project have validation request',
    props<{ request:any }>()
);

export const getHistory = createAction(
    '[Project details] get history of project',
    props<{ id:any }>()
);

export const getHistorySuccess = createAction(
    '[Project details] get history of project success',
    props<{ history:any }>()
);

export const downloadExcelSheet = createAction(
    '[Project details] download excel sheet',
    props<{ id:any, name:any }>()
);

export const downloadPdf = createAction(
    '[Project details] download pdf',
    props<{ id:any, name:any }>()
);

export const downloadCertif = createAction(
    '[Project details] download certif',
    props<{ id:any, name:any }>()
);

export const downloadSuccess = createAction(
    '[Project details] download file success'
);

export const downloadFailed = createAction(
    '[Project details] download file failed',
    props<{ error:any }>()
);

export const noDownloads = createAction(
    '[Project details] reset download',
);

export const toggleValidationModal = createAction(
    '[Project details - completed] toggle validation modal',
    props<{ canShow:any }>()
);


export const createValidationRequest = createAction(
    '[Validation modal] create validation reaquest',
    props<{ addValidationRequestDto:any }>()
);
export const createValidationRequestSuccess = createAction(
    '[Validation modal] create validation reaquest success',
    props<{ request:any }>()
);
export const createValidationRequestFailed = createAction(
    '[Validation modal] create validation reaquest failed',
    props<{ error:any }>()
);

export const changeValidationContact = createAction(
    '[Validation modal] change validation contact',
    props<{ updateResponsibleDto:any }>()
);
export const changeValidationContactSuccess = createAction(
    '[Validation modal] change validation contact success',
    props<{ contact:any }>()
);
export const changeValidationContacttFailed = createAction(
    '[Validation modal] change validation contact failed',
    props<{ error:any }>()
);

export const syncValidationContact = createAction(
    '[Validation modal] sync validation contact with user email',
    props<{ email:any }>()
);

export const toggleImportModal = createAction(
    '[Import project] toggle import project modal',
    props<{ canShow:any }>()
)

export const importProject = createAction(
    '[Import project modal] import project',
    props<{ project:any }>()
)
export const importProjectSuccess = createAction(
    '[Import project modal] import project success',
    props<{ project:any }>()
)
export const importProjectFailed = createAction(
    '[Import project modal] import project failed',
    props<{ error:any }>()
)

export const toggleShareModal = createAction(
    '[Project details] toggle share modal',
    props<{ canShow:any }>()
)

export const initState = createAction(
    '[Projects] initiate state'
)

export const ProjectsActions={
    initState,
    toggleShareModal,
    toggleImportModal,
    importProject,
    importProjectSuccess,
    importProjectFailed,
    syncValidationContact,
    changeValidationContact,
    changeValidationContactSuccess,
    changeValidationContacttFailed,
    createValidationRequest,
    createValidationRequestSuccess,
    createValidationRequestFailed,
    toggleValidationModal,
    noDownloads,
    downloadPdf,
    downloadCertif,
    downloadFailed,
    downloadSuccess,
    downloadExcelSheet,
    getValidationRequest,
    getValidationRequestSuccess,
    getHistory,
    getHistorySuccess,
    duplicateProject,
    duplicateProjectSuccess,
    duplicateProjectFailed,
    shareProject,
    shareProjectSuccess,
    shareProjectFailed,
    spliceProject,
    moveToFolderError,
    moveToFolderSuccess,
    moveToFolder,
    toggleMoveProject,
    fetchSelectedProjectFailed,
    fetchSelectedProjectSuccess,
    fetchSelectedProject,
    selectedProject,
    loadRecentProjects,
    loadRecentProjectsSuccess,
    loadRecentProjectsFailed,
    setLoading,
    createNewProject,
    createNewProjectFailed,
    createNewProjectSuccess,
    createScreeningLog,
    createScreeningLogFailed,
    emptyAction,
    searchProject,
    searchProjectSuccess,
    searchProjectFailed,
    loadingSearch,
    loadMore,
    loadMoreSuccess,
    loadMoreFailed,
    trackLastProjectsDispatcher,
    toggleLoadingMore,
    loadMoreOfSubfolder,
    loadMoreOfSubfolderSuccess,
    loadMoreOfSubfolderFailed,
    showMoveProjectModal,
    clearProjects,
    clearSubfolderProjects
}