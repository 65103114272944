import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ProjectsState } from '../../store/reducers/project.reducer';
import { ToastrService } from 'ngx-toastr';
import { ProjectsActions } from '../../store/actions/project.actions';
import { filter, take } from 'rxjs/operators';
import { selecterror, selectSharedProject } from '../../store/selectors/project.selectors';

@Component({
  selector: 'app-share-project-modal',
  templateUrl: './share-project-modal.component.html',
  styleUrls: ['./share-project-modal.component.scss']
})
export class ShareProjectModalComponent implements OnInit {

  @Input() currentProject:any=null;

  error$:Observable<any>;
  sharedProject$:Observable<any>;
  loadingShare:boolean=false;
  
  constructor(
    private projectsStore: Store<{ projects: ProjectsState }>,
    private toasterService: ToastrService,
  ) {
    this.error$ = projectsStore.select(selecterror);
    this.sharedProject$ = projectsStore.select(selectSharedProject);
   }

  ngOnInit(): void {
  }

  share(){
    this.loadingShare=true;
    this.projectsStore.dispatch(ProjectsActions.shareProject({id:this.currentProject.reflowId}));
    this.checkShare()
  }
  
  close(){
    this.projectsStore.dispatch(ProjectsActions.toggleShareModal({canShow:false}));
  }

  checkShare(){
    this.error$.pipe(
      filter(error => !!error), 
      take(1)
    ).subscribe(error => {
      if(this.loadingShare==true){
        this.loadingShare=false;
        this.toasterService.error('Operation failed');
        this.close();
      }
    });
    this.sharedProject$.pipe(
      filter(sharedProject => !!sharedProject), 
      take(1)
    ).subscribe(result => {
     
        this.loadingShare=false;
        this.toasterService.success('Project shared successfully');
        this.close();
    });
  }

}
