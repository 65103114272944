<div class="modal-body">
    <!-- Left Column -->
    <div class="column left">
      <!-- Group Header -->
      <label class="h4-heading" (click)="toogleSubGroups()">
        <em class="icon-arrow-right" *ngIf="!showSub"></em>
        <em class="icon-arrow-down" *ngIf="showSub"></em>
        {{ title }}
      </label>
  
      <!-- Sub-Groups Container -->
      <div class="sub-groups-container" *ngIf="showSub">
        <!-- Sub-Groups -->
        <div 
          class="row sub-group" 
          *ngFor="let subGroup of subGroups" 
          (click)="showEndGroups(subGroup)">
          <em class="icon-folder-closed"></em>
          <label class="sub-group-name">{{ subGroup?.name }}</label>
        </div>
  
        <!-- Project Subfolders -->
        <ng-container *ngFor="let subfolder of myPorjectsSubfolders">
          <div class="folder ml-37" >
            <hr class="subfolder-treelink" />
            <div class="d-flex flex-cont">
              <em class="icon-folder-closed"></em>
              <label class="fld-name">{{ subfolder?.folderName }}</label>
            </div>
          </div>
        </ng-container>
      </div>
  
      <!-- Private Sub-Groups -->
      <label class="h4-heading" (click)="tooglePrivate()" *ngIf="privateSubGroup?.length">
        <em class="icon-arrow-right" *ngIf="!showPrivate"></em>
        <em class="icon-arrow-down" *ngIf="showPrivate"></em>
        {{ currentCompany?.companyName }} {{ title }}
      </label>
      <div class="sub-groups-container" *ngIf="showPrivate">
        <div 
          class="row sub-group" 
          *ngFor="let subGroup of privateSubGroup" 
          (click)="showEndGroups(subGroup)">
          <em class="icon-folder-closed"></em>
          <label class="sub-group-name">{{ subGroup?.name }}</label>
        </div>
      </div>
    </div>
  
    <!-- Middle Column -->
    <div class="column mid" *ngIf="title !== 'Shared and imported products'">
      <!-- Search Box -->
      <div class="search-container">
        <div class="search-box">
          <em class="icon-search icon-search-left"></em>
          <input 
            type="text" 
            class="input-search" 
            [(ngModel)]="searchEGroup" 
            
            placeholder="Search groups..." />
        </div>
      </div>
  
      <!-- End Groups -->
      <label class="select" *ngIf="!canShowEndGroups">
        Please select a {{ title }} group...
      </label>
      <div class="end-groups-container" *ngIf="canShowEndGroups && !searchEGroup?.length">
        <ng-container *ngFor="let endGroup of endGroups; let i = index">
          <div 
            class="row end-group" 
            
            [attr.data-toggle]="'collapse'" 
            [attr.href]="'#collapseExample' + i" 
            role="button" 
            aria-expanded="false" 
            [attr.aria-controls]="'collapseExample' + i">
            <em class="icon-folder-closed"></em>
            <label class="end-group-name">{{ endGroup?.name }}</label>
          </div>
          <div class="collapse" [id]="'collapseExample' + i">
            <div 
              class="row end-group pl-4" 
              *ngFor="let process of endGroup?.processes" 
              >
              <em class="icon-folder-closed"></em>
              <label class="end-group-name">{{ process?.name }}</label>
            </div>
          </div>
        </ng-container>
      </div>
  
      <!-- Search Results -->
      <div class="end-groups-container" *ngIf="canShowEndGroups && searchEGroup?.length">
        <ng-container *ngFor="let res of searchResults; let i = index">
          <div class="row end-group"  >
            <em class="icon-folder-closed"></em>
            <label 
              class="end-group-name" 
             >
            </label>
          </div>
        </ng-container>
      </div>
    </div>
  
    <!-- Shared Components Column -->
    <div class="column mid" *ngIf="title === 'Shared and imported products'">
      <div class="end-groups-container">
        <ng-container *ngFor="let sharedComponent of sharedComponents">
            <div class="row end-group">
              <em class="icon-folder-closed"></em>
              <label class="end-group-name">{{ sharedComponent?.name }}</label>
            </div>
          </ng-container>
      </div>
    </div>
  
    <!-- Right Column -->
    <div class="column right">
      <!-- Close Button -->
      <div class="close">
        <em class="icon-close" (click)="close()"></em>
      </div>
  
      <!-- Description Section -->
      <ng-container *ngIf="canShowDescription">
        <div class="end-group-details">
          <label class="selected-name m-0">{{ selectedItem?.name }}</label>
          <label class="selected-description m-0">{{ selectedItem?.description }}</label>
          <label class="location-title m-0">Location</label>
          <div *ngIf="selectedLocation" class="selected-locations">
            <div class="location-item">
              <label class="m-0">{{ selectedLocation?.location }}</label>
              <em class="icon-close remove-location" ></em>
            </div>
          </div>
          <ng-container *ngIf="!selectedLocation">
            <div class="row dropdpwn-container mt-2">
              <div 
                class="select-location dropdown-toggle" 
                type="button" 
                id="dropdownMenuButton" 
                data-toggle="dropdown" 
                aria-haspopup="true" 
                aria-expanded="false">
                <label class="location-name">Select Region</label>
                <em class="icon-arrow-right"></em>
              </div>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button 
                  class="dropdown-item" 
                  *ngFor="let location of locations" 
                  (click)="selectLocation(location)">
                  {{ location?.location }}
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
  
      <!-- Shared Details -->
      <ng-container *ngIf="canShowSharedDetails">
        <div class="row row-wrap">
          <label class="shared-title">Company name:</label>
          <label class="shared-content">{{ selectedShared?.company?.companyName }}</label>
        </div>
        <div class="row row-wrap">
          <label class="shared-title">Product name:</label>
          <label class="shared-content">{{ selectedShared?.name }}</label>
        </div>
        <div class="row row-wrap">
          <label class="shared-title">Type:</label>
          <label class="shared-content">{{ selectedShared?.note }}</label>
        </div>
        <div class="row row-wrap">
          <label class="shared-title">Weight:</label>
          <label class="shared-content">{{ selectedShared?.weight }}</label>
        </div>
        <div class="row row-wrap">
          <label class="shared-title">Total carbon footprint:</label>
          <label class="shared-content">{{ selectedShared?.emission | number: '1.0-2' }}</label>
        </div>
      </ng-container>
  
      <!-- Add Material Button -->
      <div class="buttons" *ngIf="canShowAdd">
        <button class="small-turq add" (click)="addMaterial()">
          <label>Add</label>
        </button>
      </div>
    </div>
  </div>
  