<app-vessel-drawer *ngIf="canShowVesselDrawer$ | async" [companyId]="companyId"></app-vessel-drawer>
<div class="dashboard-container">
    
    <div class="tab-container" *ngIf="selectedTab$ |async as selectedTab">
        <div 
          class="tab" 
          [class.active]="selectedTab== 'active'"
          (click)="setActiveTab('active')"
        >
          Active vessels
        </div>
        <div 
          class="tab" 
          [class.active]="selectedTab== 'archived'"
          (click)="setActiveTab('archived')"
        >
          Archived vessels
        </div>
      </div>
      
    <ng-container  *ngIf="selectedTab$ | async as selectedTab">
        <div class="active-vessel-tab">
            <ng-container *ngIf="selectedTab$|async as selectedTab">
                <app-overview-stats *ngIf="selectedTab=='active'" [stats]="activeStats$|async" [count]="countActive$|async"></app-overview-stats>
                <app-overview-stats *ngIf="selectedTab!='active'" [stats]="archivedStats$|async" [count]="countArchived$|async"></app-overview-stats>
            </ng-container>
            
            <div class="header-content">
                <div class="header-container">
                    <div class="vessel-impact-content">
                        <p class="vessel-title">Vessel Impact</p>
                        <p class="vessel-content">This section outlines the vessel's lifecycle emissions, covering Scope 1, Scope 2, and
                            Scope 3 emissions.</p>
                    </div>
                    <div class="grid-list-btn-group" *ngIf="viewType$ | async as viewType">
    
                        <div class="menu-button" [class.active]="viewType=='grid'" (click)="toggleView('grid')"><img src="../../../assets/danish-shipping-assets/grid-dark-icon.svg" alt=""></div>
                        <div class="menu-button" [class.active]="viewType=='list'" (click)="toggleView('list')"><img src="../../../assets/danish-shipping-assets/list-icon.svg" alt=""></div>
    
                    </div>
                   
                </div>
                <div class="search-input-container">
                    <input class="search-input" type="text" name="search" autocomplete="off" placeholder="Search by name or IMO number">
                </div>
            
            </div>
    
            <ng-container *ngIf="viewType$|async as viewType" >  
                <ng-container *ngIf="selectedTab$|async as selectedTab" >  
                    <app-list-item *ngIf="viewType=='list' && selectedTab=='active'" [activeTab]="selectedTab" [vesselsList]="activeVessels$|async"></app-list-item>
                    <app-list-item *ngIf="viewType=='list' && selectedTab=='archived'" [activeTab]="selectedTab" [vesselsList]="archivedVessels$|async"></app-list-item>
                </ng-container>
            </ng-container>
            
            <ng-container *ngIf="viewType$|async as viewType" >  
                <ng-container *ngIf="selectedTab$|async as selectedTab" > 
                    <app-card-item *ngIf="viewType=='grid' && selectedTab=='active'" [activeTab]="selectedTab" [vesselsList]="activeVessels$|async"></app-card-item>
                    <app-card-item *ngIf="viewType=='grid' && selectedTab=='archived'" [activeTab]="selectedTab" [vesselsList]="archivedVessels$|async"></app-card-item>
                </ng-container>
            </ng-container>
            
            
        </div>
    </ng-container>

</div>