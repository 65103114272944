import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "../services/user.service";
import { UserState } from "../store/reducers/user.reducers";
import { Store } from "@ngrx/store";
import {  UserActions } from "../store/actions/user.actions";

@Component({
  selector: "app-service-provider-space",
  templateUrl: "./service-provider-space.component.html",
  styleUrls: ["./service-provider-space.component.css"],
})
export class ServiceProviderSpaceComponent implements OnInit {

  companyId:any=null;

  constructor(
    private activatedRoute: ActivatedRoute, 
    private router: Router,
    private userService:UserService,
    private store: Store<{ user: UserState }>
  ) {
   }

  ngOnInit(): void {
    this.companyId = this.activatedRoute.snapshot.paramMap.get('companyId');
    
    
    document.body.style.background = 'white';
    this.userService.getCurrentUser().subscribe(res=>{
      this.store.dispatch(UserActions.registerCurrentUser({user:res}));
      this.activatedRoute.params.subscribe((params) => {
        if (Number(params["companyId"]) != res.companyId || res.type != "Supplier") {
          this.userService.logoutUser(res.userId).subscribe(user=>{
            localStorage.clear();
            this.router.navigate(["/login"]);
          });
        }
        else{
          localStorage.setItem("user_id", res.userId);
          localStorage.setItem("user_role", res.role);
          localStorage.setItem("company_type", res.type);
          localStorage.setItem("company_id", res.companyId);
          localStorage.setItem("permission", res.permission);
          localStorage.setItem("checkedUpdates", res.acceptUpdates);
        }
      });
    },err=>{
        localStorage.clear();
        this.router.navigate(["/login"]);
    });
    
  }
}
