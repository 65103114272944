<div id="openModal-about" class="modalDialog">
    <div class="re-modal-content" *ngIf="showFirst">

        <div class="re-modal-header">
            <div class="project-validation-header">
                <em class="icon-close close" (click)="close()"
                style="color: #ffffff !important; margin-top: 1px;margin-right: 5px;"></em>
            </div>
        </div>
        <div class="content-modal">
            <label class="modal-title">Project Validation</label>
            <div class="row">
                <div class="text1">
                    <span></span>
                    <label class="little-title">What is the project validation ?</label>
                </div>
                <div class="text1">
                    <span>
                        <em class="icon-info bigger-info"></em>
                    </span>
                    <p class="whatIsValidationText">
                        The project validation is an external review of the project conducted by Environmental Engineers
                        from the Environmental Engineering Department of ReFlow.
                        All project stages are reviewed individually including the life cycle stages, the inventory and
                        provided data,
                        and the project assessment outcomes to correlate with the international life cycle
                        assessment ISO standards.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="text1">
                    <span></span>
                    <label class="little-title">How does it work ? </label>
                </div>
                <div class="text1">
                    <span>
                        <em class="icon-accomplished"></em>
                    </span>
                    <p class="whatIsValidationText">
                        The project validation is divided into two steps; the correction phase and the completion phase.
                        For the correction phase, the Environmental Engineers review and correct all steps of the
                        project hence providing a Validation Correction Scheme with potential improvements for complying
                        with the LCA standards.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="text1">
                    <span></span>
                    <label class="little-title">What do you get from validating your project ?</label>
                </div>
                <div class="text1">
                    <span>
                        <img src="../../../../assets/asset58.png" alt="" style="height: 43px;">
                    </span>
                    <p class="whatIsValidationText">
                        The validation is a buy-up feature with a price of 5000 DKK per validation, including project
                        review,
                        Validation Correction Scheme, second project review, and Certificate of Validation. Engineering
                        support is included in your subscription.
                    </p>
                </div>
            </div>
            <div class="row footer-row">
                <div class="contain" style="display:flex ;flex-direction: column;text-align: center;">
                    <div style="justify-content:center;align-items:center ;text-align: center;width: 100%;">
                        <p class="explain-text">
                            By pressing “Submit your validation request” you consent to that ReFlow may contact you regarding
                            the validation of your assessment and for the validation process itself. Furthermore, you give your
                            consent to that ReFlow may access your data on the specific assessment for validation purposes only.
                        </p>
                    </div>
                    <div style="justify-content:center;align-items:center ;">
                        <button class="btn-submit" (click)="createVAlidationRequest()">Submit your validation request</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="re-modal-content-loading" *ngIf="showLoading">

        <div class="re-modal-header">
            <div class="project-validation-header1">
                <em class="icon-close close" (click)="close()"
                style="color: #ffffff !important; margin-top:1px;margin-right: 5px;"></em>
            </div>
        </div>
        <div class="content-modal content-modal-loading ">
            <div class="pending-container">
                <label class="providing-title">Providing validation access</label>
                <div class="spinner-container">
                    <div class="spinner-border text-secondary"></div>
                    <label class="h4-heading loading">Please wait while linking to Reflow environmental engineering
                        Department</label>
                </div>
            </div>
        </div>
    </div>
    <div class="re-modal-content-providing" *ngIf="showSubmitted">
        <div class="re-modal-header">
            <div class="project-validation-header2">
                <em class="icon-close close" (click)="close()" style="color: #ffffff !important; margin-top:1px;margin-right: 5px;"></em>
            </div>
            
        </div>
        <div class="submitted-body-container">
            <label class="modal-title">Project Submitted For Validation</label>
            <label class="description" >Your project has been successfully submitted for validation by the Reflow Environmental Engineering Department</label>
            <div class="esmatimation-container">
                <div style="justify-content: center ; margin-left: 20px;margin-top: 10px;">
                    <img src="../../../../assets/asset55.png" style="margin-top: 13px;">
                </div>
                <label class="estimation-text">The validation process is estimated to be finalized in :<p style="font: normal normal normal 12px/16px Nunito Sans, sans-serif;">7 days</p> </label>
            </div>
            <div class="esmatimation-container" style="margin-top: 20px;">
                <div style="justify-content: center ; margin-left: 20px;margin-top: 10px;">
                    <img src="../../../../assets/asset56.png" style="margin-top: 13px;">
                </div>
                <label class="estimation-text">The validation improvement scheme will be provided to: <p
                        style="font: normal normal normal 12px/16px Nunito Sans, sans-serif;">{{user?.email}}</p> </label>
                <label class="change-contact-info" (click)="showContactModal()">Change contact information</label>
            </div>
            <div class="esmatimation-container" style="margin-top: 20px;">
                <div style="justify-content: center ; margin-left: 20px;margin-top: 10px;">
                    <em class="icon-accomplished"></em>
                </div>
                <label class="estimation-text2">The validation process applies to projects
                    :<p style="font: normal normal normal 12px/16px Nunito Sans, sans-serif;">{{currentProject?.name}} </p> </label>
            </div>
            <label class="description" style="margin-top: 25px; width: 90%;">A notification will be send to the provided email address upon completion of
                the validation process. A status icon will appear on the applied project throughout the validation
                project. A validation icon will appear together with a downloadable certificate of validation when the
                project is validated and corrected</label>
            <button class="close" (click)="close()">Close</button>
        </div>
    </div>
    <div class="re-modal-content-contact" *ngIf="showContact">
        <div class="re-modal-header">
            <div class="project-validation-header3">
                <em class="icon-close close" (click)="close()"style="color: #ffffff !important; margin-top:1px;margin-right: 5px;"></em>
            </div>
        </div>
        <div class="email-container">
            <label class="contact-title">Change contact information</label>
            <label class="contact-desc">Please type in the which email we should contact</label>
            <input class="emailInput" type="text" placeholder="Email" [(ngModel)]="email">
            <button class="save" (click)="saveContact()">Save</button>
        </div>
    </div>
</div>