import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { UserState } from '../../../../store/reducers/user.reducers';
import { VesselsService } from '../../../../services/danish-shipping-services/vessels.service';
import { VesselsOverviewState } from '../reducers/vessels-overview.reducers';
import { VesselsOverviewActions } from '../actions/vessels-overview.actions';
import { selectCompanyId } from '../../../../store/selectors/user.selectors';

@Injectable()
export class VesselsOverviewEffects {
  constructor(
    private actions$: Actions,
    private vesselsService: VesselsService,
    private vesselsOverviewStore: Store<{ vesselsOverview: VesselsOverviewState }>,
    private userStore: Store<{ user: UserState }>,
  ) {}

  getActiveVessels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VesselsOverviewActions.getActiveVessels),
      mergeMap(action => {
        return this.vesselsService.getActiveVessels(action.id).pipe(
            map(vessels => {
                return VesselsOverviewActions.getActiveVesselSuccess({ vessels });
            }),
            catchError(({ error }) => {
              return of(VesselsOverviewActions.getActiveVesselFailed({error}));
            })
        );
      })
    )
  );

  getArchivedVessels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VesselsOverviewActions.getArchivedVessels),
      mergeMap(action => {
        return this.vesselsService.getArchivedVessels(action.id).pipe(
            map(vessels => {
                return VesselsOverviewActions.getArchivedVesselSuccess({ vessels });
            }),
            catchError(({ error }) => {
              return of(VesselsOverviewActions.getArchivedVesselFailed({error}));
            })
        );
      })
    )
  );

  archiveVessel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VesselsOverviewActions.archiveVessel),
      mergeMap(action => {
        return this.vesselsService.archiveVessel(action.vessel.id).pipe(
            map(archivedVessel => {
                let ans=action.vessel;
                ans.isArchived=true;
                this.userStore.pipe(
                  select(selectCompanyId),
                  take(1)
                ).subscribe(companyId => {
                  this.vesselsOverviewStore.dispatch(VesselsOverviewActions.getActiveStats({id:companyId}));
                  this.vesselsOverviewStore.dispatch(VesselsOverviewActions.getArchivedStats({id:companyId}));
                });
                
                return VesselsOverviewActions.archiveVesselSuccess({ archivedVessel:ans });
            }),
            catchError(({ error }) => {
              return of(VesselsOverviewActions.archiveVesselFailed({error}));
            })
        );
      })
    )
  );

  restoreVessel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VesselsOverviewActions.restoreVessel),
      mergeMap(action => {
        return this.vesselsService.restoreVessel(action.vessel.id).pipe(
            map(archivedVessel => {
                let ans=action.vessel;
                ans.isArchived=false;
                this.userStore.pipe(
                  select(selectCompanyId),
                  take(1)
                ).subscribe(companyId => {
                  this.vesselsOverviewStore.dispatch(VesselsOverviewActions.getActiveStats({id:companyId}));
                  this.vesselsOverviewStore.dispatch(VesselsOverviewActions.getArchivedStats({id:companyId}));
                });
                return VesselsOverviewActions.restoreVesselSuccess({ restoredVessel:ans });
            }),
            catchError(({ error }) => {
              return of(VesselsOverviewActions.restoreVesselFailed({error}));
            })
        );
      })
    )
  );

  getActiveStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VesselsOverviewActions.getActiveStats),
      mergeMap(action => {
        return this.vesselsService.getActiveStats(action.id).pipe(
            map(stats => {
                return VesselsOverviewActions.getActiveStatsSuccess({ stats });
            }),
            catchError(({ error }) => {
              return of(VesselsOverviewActions.getActiveStatsFailed({error}));
            })
        );
      })
    )
  );

  getArchivedStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VesselsOverviewActions.getArchivedStats),
      mergeMap(action => {
        return this.vesselsService.getArchivedStats(action.id).pipe(
            map(stats => {
                return VesselsOverviewActions.getArchivedStatsSuccess({ stats });
            }),
            catchError(({ error }) => {
              return of(VesselsOverviewActions.getArchivedStatsFailed({error}));
            })
        );
      })
    )
  );

  importNewVesselData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VesselsOverviewActions.importVesselData),
      mergeMap(action => {
        return this.vesselsService.importNewVesselData(action.imo).pipe(
            map(newVesselData => {
                return VesselsOverviewActions.importVesselDataSuccess({ newVesselData });
            }),
            catchError(({ error }) => {
              return of(VesselsOverviewActions.getArchivedStatsFailed({error}));
            })
        );
      })
    )
  );

  createVesssel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VesselsOverviewActions.createVessel),
      mergeMap(action => {
        return this.vesselsService.createVessel(action.vessel).pipe(
            map(createdVessel => {
                return VesselsOverviewActions.createVesselSuccess({ createdVessel });
            }),
            catchError(({ error }) => {
              return of(VesselsOverviewActions.createVesselFailed({error}));
            })
        );
      })
    )
  );

  
}