<div id="myModal" class="modal">
    <div class="modal-content import-modal">
        <div class="modal-header">
            <em class="icon-close close-import" (click)="hideImportModal()"></em>
        </div>
        <div class="modal-body">    
            <label class="share-title import-title">Do you want to import this assessment?</label>
            <label class="share-description import-description">Please past the received calculation code below. When
                importing you will be able to see the calculation result and use the product in you own
                calculation</label>
            <input class="form-control" [(ngModel)]="importLink" type="text"
                placeholder=" Paste your reflow code here">
            <div class="btn-div">
                <button class="big-blue-outline import-btn" (click)="importScreening()">
                    <em class="icon-download icon-import-btn"></em>&nbsp;Import
                </button>
            </div>
        </div>
    </div>
</div>
