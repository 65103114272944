import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ProjectsState } from '../../store/reducers/project.reducer';
import { ProjectsActions } from '../../store/actions/project.actions';
import { Observable } from 'rxjs';
import { selectsSearchResult } from '../../store/selectors/project.selectors';
import { Router } from '@angular/router';

@Component({
  selector: 'app-quantify-search',
  templateUrl: './quantify-search.component.html',
  styleUrls: ['./quantify-search.component.scss']
})
export class QuantifySearchComponent implements OnInit {

  public focusSearchKeyWord:boolean=false;
  public searchKeyWord:string="";
  public searchList$:Observable<any>;
  public loading$:Observable<any>;
  @Input() companyId:any=null;
  constructor(
    private projectsStore: Store<{ projects: ProjectsState }>,
    private router: Router,
  ) {
    this.searchList$=this.projectsStore.select(selectsSearchResult)
    this.loading$=this.projectsStore.pipe(
      select(state=>state.projects.loadingSearch)
    )
   }

  ngOnInit(): void {
    
  }

  public makeSearch(){
    this.projectsStore.dispatch(ProjectsActions.searchProject({search:{companyId:this.companyId,screeningName:this.searchKeyWord}}))
  }

  public processBlur(){

  }

  editSceening(item){
    this.router.navigate([
      "homeServiceProvider",
      this.companyId,
      "quantify",
      "screening",
      item.id
    ]);
  }

}
