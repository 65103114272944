import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { CompanyFoldersService } from '../../../../services/company-folders.service';
import { FoldersActions } from '../actions/folder.actions';
import { Store } from '@ngrx/store';
import { FoldersState } from '../reducers/folder.reducer';
import { QuantifyService } from '../../../../services/quantify.service';
import { ProjectsState } from '../reducers/project.reducer';
import { ProjectsActions } from '../actions/project.actions';

@Injectable()
export class FolderEffects {
  constructor(
    private actions$: Actions,
    private companyFoldersService: CompanyFoldersService,
    private quantifyService:QuantifyService,
    private store: Store<{ folders: FoldersState }>,
    private projectsStore: Store<{ projects: ProjectsState }>,
  ) {}
  createFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FoldersActions.createFolder),
      tap(() => {
        this.store.dispatch(FoldersActions.setLoading({ loading: true }));
      }),
      mergeMap(action => {
        return this.companyFoldersService.createFolder(action.folder).pipe(
          map(folder => {
            this.store.dispatch(FoldersActions.setLoading({ loading: false }));
            if (folder.isCompleted == true) {
              return FoldersActions.createCompletedFolderSuccess({ folder });
            } else {
              return FoldersActions.createDraftFolderSuccess({ folder });
            }
          }),
          catchError(error => of(FoldersActions.createFolderFailed({ error })))
        );
      })
    )
  );

  fetchFolders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FoldersActions.fetchFolders),
     
      mergeMap(action => {
        return this.quantifyService.getFoldersOfCompany(action.getFolderDto).pipe(
          map(foldersList => {
  
            if (action.getFolderDto.status == 'draft') {
              return FoldersActions.fetchDraftSuccess({ foldersList });
            } else if (action.getFolderDto.status == 'completed') {
              return FoldersActions.fetchCompletedSuccess({ foldersList });
            }else {
              return FoldersActions.fetchArchivedSuccess({ foldersList });
            }
          }),
          catchError(error => of(FoldersActions.fetchFoldersFailed({ error })))
        );
      })
    )
  );

  createSubFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FoldersActions.createSubFolder),
     
      mergeMap(action => {
        return this.companyFoldersService.createFolder(action.subfolder).pipe(
          map(folder => {
            console.log(folder);
            
            return FoldersActions.upsertFolderSuccess({ folder,success:"Folder created successfully" });

          }),
          catchError(error => of(FoldersActions.fetchFoldersFailed({ error })))
        );
      })
    )
  );

  updateFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FoldersActions.editFolder),
     
      mergeMap(action => {
        return this.companyFoldersService.editFolder(action.folder).pipe(
          map(folder => {
            
            return FoldersActions.upsertFolderSuccess({ folder,success:"Folder updated successfully" });

          }),
          catchError(error => of(FoldersActions.fetchFoldersFailed({ error })))
        );
      })
    )
  );

  archiveFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FoldersActions.archiveFolder),
     
      mergeMap(action => {
        return this.quantifyService.archiveFolder(action.id).pipe(
          map(folder => {
            
            return FoldersActions.archiveFolderSuccess({ folder:action.id,success:"Folder archived successfully" });

          }),
          catchError(error => of(FoldersActions.archiveFolderFailed({ error })))
        );
      })
    )
  );

  restoreFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FoldersActions.restoreFolder),
     
      mergeMap(action => {
        return this.quantifyService.restoreFolder(action.id).pipe(
          map(folder => {
            
            return FoldersActions.restoreFolderSuccess({ folder:action.id,success:"Folder restored successfully" });

          }),
          catchError(error => of(FoldersActions.archiveFolderFailed({ error })))
        );
      })
    )
  );

  archiveProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FoldersActions.archiveProject),
      mergeMap(action => {
        return this.quantifyService.archiveProject(action.id).pipe(
            map(project => {
                this.projectsStore.dispatch(ProjectsActions.spliceProject({key:action.key,id:action.id}))
                return FoldersActions.archiveProjectSuccess({ project });
            }),
            catchError((error) => {
              return of(FoldersActions.archiveProjectSuccess(error));
            })
        );
      })
    )
  );

  restoreProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FoldersActions.restoreProject),
      mergeMap(action => {
        return this.quantifyService.restoreProject(action.id).pipe(
            map(project => {
              this.projectsStore.dispatch(ProjectsActions.spliceProject({key:action.key,id:action.id}))
                return FoldersActions.restoreProjectSuccess({ project });
            }),
            catchError((error) => {
              return of(FoldersActions.restoreProjectFailed(error));
            })
        );
      })
    )
  );
}