import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Screening, ScreeningLog } from 'src/app/models/screening.model';
import { SalesService } from 'src/app/services/sales.service';
import { ScreeningService } from 'src/app/services/screening.service';

@Component({
  selector: 'app-edit-screening',
  templateUrl: './edit-screening.component.html',
  styleUrls: ['./edit-screening.component.css']
})
export class EditScreeningComponent implements OnInit {
  public currentScreening: Screening;
  public currentState: string = "";
  public companyId: number;
  public maxtStateId: number = 1;
  public log:boolean=false;
  public hasResult:boolean=false;
  public screeningLogs:ScreeningLog[];


  public menuManager={
    "screening-setup":1,
    "system-definition":2,
    "screening-inventory":3,
    "review":4,
    "result":5,
  }
  constructor(private router: Router, private screeningService: ScreeningService, private activatedRoute: ActivatedRoute, private toastrService:ToastrService, private salesService:SalesService ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      let screeningId = Number(params["screeningId"]);
      this.screeningService.getScreeningById(screeningId).subscribe(res => {
        this.currentScreening = res;
        if(res.screeningResult!=null){
          this.hasResult=true;
        }
        this.currentState = res.state;
        this.maxtStateId=Math.max(this.menuManager[this.currentState]);
        this.screeningService.getScreeningLogById(screeningId).subscribe(logs=>{
          this.screeningLogs=logs;
        });
      });
    });
    this.companyId = Number(localStorage.getItem("company_id"));
  }

  public exit(): void {
    this.router.navigate([
      "/homeServiceProvider",
      this.companyId,
      "quantify",
    ]);
  }

  public closeScreeningSetup(event: any) {
    this.attemptChangeState(2,'system-definition');
    if (event == "next") {
      this.currentState = "system-definition";
    }
    else if (event == "close") {
      this.exit();
    }
  }

  public closeSystemDefinition(event: any) {
    this.attemptChangeState(3,'screening-inventory');
    if (event == "next") {
      this.currentState = "screening-inventory";
    }
    else if (event == "close") {
      this.exit();
    }
    else if (event == "previous") {
      this.currentState = "screening-setup";
    }
  }

  public closeScreeningInventory(event:any){
    this.attemptChangeState(4,'review');
    if (event == "next") {
      this.currentState = "review";
    }
    else if (event == "close") {
      this.exit();
    }
    else if (event == "previous") {
      this.currentState = "system-definition";
    }
  }

  public closeScreeningReview(event:any){
    if (event == "previous") {
      this.currentState = "screening-inventory";
    }
    if (event == "next") {
      this.currentState = "result";
    }
    else if (event == "close") {
      this.exit();
    }
    else{
      this.currentState=event; 
    }
  }

  public attemptChangeState(stateId,statekey){
    if(this.hasResult==false && stateId==5 ){
      this.toastrService.error("You didn't finish the assessment of your project","Error");
      return ;
    }
    if(this.menuManager[statekey]<=stateId){
      this.maxtStateId=Math.max(this.maxtStateId,stateId);
      this.currentState = statekey;
    }
  }

  public manageMenu(key){
    return (this.menuManager[key]<=this.maxtStateId)
  }

  public showLog(){
    this.log=!this.log;
  }
}
