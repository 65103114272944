import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuantifyComponent } from './quantify.component';
import { QuantifyCalculatorComponent } from './quantify-calculator/quantify-calculator.component';
import { EditScreeningComponent } from './edit-screening/edit-screening.component';

const routes: Routes = [
  {
    path:'',
    component:QuantifyComponent,
    children:[
      {
        path:'',
        component:QuantifyCalculatorComponent
      },
      {
        path:'screening/:screeningId',
        component:EditScreeningComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class QuantifyRoutingModule { }
